/* login */
.login-layout {
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    max-width: 1440px;
    max-height: 1000px;
    min-width: 1250px;
    min-height: 640px;
    background: url("https://h.cjz.vip/picture/login_banner.png") no-repeat 100%;
    /*background: url("../../assets/img/login_banner.png") no-repeat center;*/
    background-size: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.login-layout-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: no-repeat 50%/cover;
}

.login-layout-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
    position: relative;
    padding: 3% 7% 1%;
    width: 100%;
    height: 100%;
}

.login-layout-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5%;
}

.login-layout-header-logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: 100%;
}

.login-layout-header-img {
    z-index: 10;
    width: 30px;
    height: 30px;
}

.login-img {
    width: 100%;
    height: 100%;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.login-layout-header-text {
    z-index: 10;
    margin-left: 20px;
    width: auto;
    height: auto;
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 25px;
}

.login-page-language {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 10px;
    width: 100px;
    height: 35px;
}

.login-page-language-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /*padding: 0;*/
    width: 100%;
    height: 100%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    /*border-radius: 10px;*/
    cursor: pointer;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.9);
}

.login-page-language-box:hover {
    font-weight: bolder;
}

.login-page-language-box:focus {
    font-weight: bolder;
}

.login-page-language-item {
    text-align: center;
    background-color: rgba(255, 255, 255, 0.5);
    color: #ffffff;
}

.login-page-language-item:hover {
    text-align: center;
    background-color: rgba(180, 180, 180, 0.7);
    color: #ffffff;
}

.login-page-language-item:focus {
    text-align: center;
    background-color: rgba(180, 180, 180, 0.7);
    color: #ffffff;
}

.login-layout-center {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 500px;
}

.login-layout-slogan {
    position: relative;
    width: 50%;
    height: auto;
}

.login-layout-slogan-title {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: bolder;
    font-family: AlibabaPuHuiTi_2_115_Black;
    color: #FFFFFF;
    line-height: 50px;
    letter-spacing: 2px;
}

.login-layout-slogan-des {
    margin: 10px 0;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 36px;
    white-space: pre-line;
}

.login-layout-container {
    position: relative;
    margin: 0 auto;
    width: 400px;
    height: 400px;
}

.login-layout-fPwd-container {
    position: relative;
    margin: 0 auto;
    padding: 20px 0;
    width: 400px;
    height: 500px;
}

.login-layout-win {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    /*align-content: center;*/
    /*justify-content: space-evenly;*/
    padding: 5% 0;
    width: 100%;
    height: 100%;
    background: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    white-space: pre-wrap;
}

.login-layout-win-welcome {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 10px auto;
    width: 85%;
    height: 20%;
    font-size: 20px;
    font-weight: bolder;
    line-height: 40px;
}

.login-layout-win-login {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin: 20px auto 0;
    width: 85%;
    height: 80%;
    font-size: 14px;
    color: gray;
}

.login-layout-win-login-element {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 20%;
}

.login-layout-win-login-text {
    text-align-last: justify;
    min-width: 12%;
    height: auto;
}

.login-layout-win-login-input-wrap {
    width: 72%;
    height: 70%;
}

.login-layout-win-login-input {
    display: flex;
    align-items: center;
    width: 72%;
    height: 70%;
    border-radius: 5px;
    border: 1px solid #D9E0E9;
}

.login-layout-win-login-input:placeholder-shown {
    font-size: 12px;
}

input:focus {
    outline: none;
}

.login-layout-win-login-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    width: 100%;
    height: 40px;
    border: 0;
    border-radius: 5px;
    background-color: #1859DE;
    color: white;
    cursor: pointer;
}

.login-layout-win-login-other {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 10%;
}

.login-layout-win-login-other-wrap {
    display: flex;
    align-items: center;
    height: 100%;
}

.login-layout-win-login-qr {
    /*margin-right: 10px;*/
    width: auto;
    font-size: 12px;
    color: #1859DE;
    cursor: pointer;
}

.login-layout-win-login-pf {
    font-size: 12px;
    color: #1859DE;
    cursor: pointer;
}

.login-layout-copyright {
    position: relative;
    bottom: 20px;
    left: 25%;
    text-align: center;
    width: 50%;
    height: 20px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: rgba(176, 176, 176, 0.5);
}

.register-layout-container {
    position: relative;
    margin: 0 auto;
    padding: 20px 0;
    width: 400px;
    height: 450px;
    background: #FFFFFF;
    box-shadow: 0 8px 16px 0 rgba(119, 119, 119, 0.2);
    border-radius: 10px;
}

.register-layout-win-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    width: 90%;
    height: 15%;
}

.nav-reg {
    width: auto;
    height: 80%;
    font-size: 16px;
    font-weight: bolder;
    color: gray;
    cursor: pointer;
}

.nav-reg-selected {
    width: auto;
    height: 80%;
    font-size: 16px;
    font-weight: bolder;
    color: black;
    cursor: pointer;
}

.nav-reg-selected::after {
    content: '';
    display: block;
    margin: 0 auto;
    width: 50%;
    height: 30%;
    border-bottom: 4px solid #246bbe;
    cursor: pointer;
}

.register-layout-win-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 85%;
    height: 85%;
}

.register-layout-win-element {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 15%;
}

.register-layout-win-text {
    display: flex;
    align-items: center;
    width: 15%;
    height: 70%;
    color: gray;
}

.text-justify {
    /*text-align-last: justify;*/
    width: 100%;
}

.register-layout-win-phone {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 73%;
    height: 70%;
}

.register-layout-win-country {
    z-index: 10;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 4%;
    /*border-radius: 5px;*/
    border-right: 1px solid #D9E0E9;
    font-size: 14px;
    color: black;
}

.register-layout-win-country-icon {
    margin-left: 5px;
    font-size: 12px;
}

.register-layout-win-input {
    display: flex;
    align-items: center;
    width: 73%;
    height: 70%;
    border-radius: 5px;
    border: 1px solid #D9E0E9;
}

.register-layout-win-input:placeholder-shown {
    font-size: 12px;
}

.register-layout-win-input > input.ant-input:placeholder-shown {
    font-size: 12px;
}

.login-layout-win-login-input > input.ant-input:placeholder-shown {
    font-size: 12px;
}

.register-layout-win-input-notice {
    display: flex;
    align-items: center;
    width: 100%;
    height: 15px;
    color: red;
    font-size: 12px;
    line-height: 20px;
    word-break: break-all;
}

.register-layout-win-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5% auto 2%;
    width: 100%;
    height: 12%;
    border: 0;
    border-radius: 5px;
    background-color: #1859DE;
    color: white;
    cursor: pointer;
}

.register-layout-win-other {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 10%;
    font-size: 12px;
}

.register-layout-win-other-left {
    color: #666666;
}

.register-layout-win-other-right {
    margin-left: 2px;
    font-weight: bold;
    color: #1859DE;
    cursor: pointer;
}

.register-search {
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin: 10px auto;
    width: 90%;
    height: 40px;
    background: #F9F9FB;
    border: 0;
    font-size: 14px;
}

/* mobile */
.mobile-login-layout {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
}

.mobile-login-container {
    /*margin: 20px auto 0;*/
    width: 100%;
    height: 450px;
}

.mobile-login-win {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    /*justify-content: space-evenly;*/
    margin: 0 auto;
    width: 85%;
    height: 100%;
    background: white;
}

.mobile-login-win-welcome {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 auto 20px;
    width: 100%;
    height: 20%;
    font-size: 24px;
    font-weight: bolder;
    white-space: pre-wrap;
}

.mobile-login-win-login {
    margin: 0 auto;
    width: 100%;
    height: auto;
    font-size: 14px;
    color: gray;
}

.mobile-login-win-login-element {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
}

.mobile-input-send-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 0;
    width: 100%;
    height: 40px;
}

.mobile-input-wrap {
    width: 100%;
    height: 50px;
}

.mobile-login-win-login-input {
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: 100%;
    height: 50px;
    background: #F9F9FB;
    border-radius: 6px;
    font-size: 14px;
    border: 1px solid rgba(128, 128, 128, 0.3);
}

.mobile-login-win-login-input:placeholder-shown {
    font-size: 14px;
}

.mobile-send-ver-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: auto;
    height: 40px;
    font-size: 16px;
}

.mobile-login-win-login-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto 10px;
    width: 100%;
    height: 50px;
    border: 0;
    border-radius: 5px;
    background-color: #337AF0;
    color: white;
}

.mobile-login-win-login-other {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 10%;
}

.mobile-login-win-login-other-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobile-login-win-login-qr {
    font-size: 16px;
    color: #337AF0;
}

.mobile-login-win-login-pf {
    font-size: 16px;
    color: #337AF0;
}

.mobile-login-copyright {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 262px;
    background: url("../../assets/img/img_bg_m.png") no-repeat center;
    background-size: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.mobile-login-copyright-text {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #777777;
    line-height: 100px;
}

.mobile-login-ver {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    /*align-content: center;*/
    /*justify-content: space-evenly;*/
    margin: 0 auto;
    width: 85%;
    height: 100%;
    background: white;
}

.mobile-login-ver-title {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 auto;
    width: 100%;
    height: 50px;
    font-size: 24px;
    font-weight: bolder;
}

.mobile-login-ver-text {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #777777;
    line-height: 40px;
}

.mobile-reg-win-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: auto;
}

.mobile-nav-reg {
    margin: 0 30px;
    width: auto;
    height: 80%;
    font-size: 20px;
    font-weight: bolder;
    color: #777777;
}

.mobile-nav-reg-selected {
    margin: 0 30px;
    width: auto;
    height: 80%;
    font-size: 20px;
    font-weight: bolder;
    color: #0D1E3D;
}

.mobile-nav-reg-selected::after {
    content: '';
    display: block;
    margin: 10px auto 0;
    width: 25px;
    height: 4px;
    border-radius: 5px;
    background: #337AF0;
}

.mobile-reg-win-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 40px auto;
    width: 100%;
    height: 85%;
}

.mobile-function-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto 10px;
    width: 100%;
    height: 50px;
    border: 0;
    border-radius: 5px;
    background-color: #337AF0;
    color: white;
    cursor: pointer;
}

.mobile-register-layout-win-other {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 10%;
    font-size: 14px;
}

/*.ant-input-affix-wrapper > input.ant-input {*/
/*    background-color: ;*/
/*}*/

.mobile-login-win-login-input > input.ant-input {
    background-color: #F9F9FB;
}

.register-search > input.ant-input {
    background: #F9F9FB;
}

/*.ant-input:placeholder-shown {
    font-size: 12px;
}*/

/* placeholder */
::-webkit-input-placeholder {
    color: #666666 !important;
}

::-moz-placeholder { /* Firefox 19+ */
    color: #666666 !important;
}

::-moz-placeholder {   /* Mozilla Firefox 4 to 18 */
    color: #666666 !important;
}

.ant-select-search__field__placeholder{
    color: #666666 !important;
}

input:-ms-input-placeholder { /* IE 10+ */
    color: #666666 !important;
}

textarea:-ms-input-placeholder { /* IE 10+ */
    color: #666666 !important;
}

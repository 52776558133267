/* modal */
.modal-bg-win {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    /*border-top-left-radius: 20px;*/
    background: rgba(0, 0, 0, 0.7);
    overflow: hidden;
}

.modal-win {
    z-index: 20;
    position: fixed;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    top: calc(50% - 145px);
    left: calc(50% - 190px);
    padding: 1% 0;
    width: 380px;
    height: 290px;
    background: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
    border-radius: 20px;
    white-space: pre-wrap;
}

.modal-win-title {
    font-size: 16px;
    font-weight: bolder;
    color: #2F3241;
    line-height: 26px;
}

.modal-win-send {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 80%;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
}

.modal-win-ver {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 80%;
    height: 60px;
    background: #FFFFFF;
    border: 0;
}

.modal-win-input {
    display: flex;
    align-items: center;
    width: 60%;
    height: 45px;
    border-radius: 5px;
    border: 1px solid #D9E0E9;
    font-size: 14px;
}

.ant-input-disabled {
    color: rgba(0, 0, 0, 0.8) !important;
    background-color: #f5f5f5;
}

.modal-win-send-button {
    z-index: 10;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 8%;
    width: 80px;
    height: 15px;
    border-left: 1px solid #F0F0F0;
    background-color: white;
    font-size: 12px;
    cursor: pointer;
}

.login-modal-reg-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 80%;
    height: 45px;
    background: #236BF3;
    border: 0;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 20px;
    cursor: pointer;
}

.modal-win-close {
    position: fixed;
    top: calc(50% - 160px);
    left: calc(50% + 190px);
    padding-left: 1%;
    width: auto;
    height: auto;
    transform: scale(0.8);
    border: 0;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    font-size: 28px;
    color: white;
}

.modal-go-win {
    z-index: 20;
    position: fixed;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    top: calc(50% - 150px);
    left: calc(50% - 200px);
    padding: 2% 0;
    width: 400px;
    height: 300px;
    background: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
    border-radius: 20px;
    white-space: pre-wrap;
}

.modal-go-win-title {
    font-size: 16px;
    font-weight: bolder;
    color: #2F3241;
    line-height: 20px;
}

.modal-go-win-vgo-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-go-win-img {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    height: 158px;
    background: #F7F8FB;
    border-radius: 16px;
}

.modal-go-win-img-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 55%;
    height: 80%;
}

.modal-go-win-img-seKey {
    font-size: 14px;
    font-weight: 400;
    color: #4A5563;
    line-height: 50px;
}

.modal-go-win-ver {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 80%;
    height: 60px;
    background: #FFFFFF;
    border: 0;
}

.modal-go-win-input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70%;
    border-radius: 5px;
    border: 1px solid #4b64d0;
    font-size: 14px;
}

.login-go-modal-reg-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 80%;
    height: 40px;
    background: #236BF3;
    border: 0;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 20px;
    cursor: pointer;
}

/*.login-go-modal-reg-button::selection {*/
/*    outline: none;*/
/*    border: 0;*/
/*}*/

/*.login-go-modal-reg-button:focus {*/
/*    outline: none;*/
/*    border: 0;*/
/*}*/

.modal-go-win-close {
    z-index: 30;
    position: fixed;
    top: calc(50% - 210px);
    left: calc(50% + 200px);
    padding-left: 1%;
    width: auto;
    height: auto;
    border: 0;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    font-size: 28px;
    color: white;
}

/* TABLE */
.ant-table table {

}

.ant-table-thead > tr > th {
    /*vertical-align: top;*/
    height: 60px !important;
    border-top: 1px solid #F0F0F0;
    border-bottom: 0;
    background: #FFFFFF;
    font-size: 12px;
    color: #8B8BA0;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    height: 0;
}

.ant-table-tbody > tr > td {
    font-size: 12px;
    border-bottom: 0;
}

.mobile-utc {
    position: relative;
    margin: 5px auto;
    width: 90%;
    height: 26px;
    font-size: 12px;
    color: #0D1E3D;
    line-height: 26px;
}

.refresh-button {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 80px;
    height: 30px;
    background: #F8FAFF;
    border-radius: 8px;
    border: 1px solid #1858DB;
    font-size: 12px;
    font-weight: 400;
    color: #1858DB;
    line-height: 30px;
    cursor: pointer;
}

/* mobile */
.mobile-modal-bg-win {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    background: rgba(0, 0, 0, 0.7);
}

/* about */
.about-layout {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    min-width: 1280px;
    max-width: 2200px;
}

.about-layout-wrapper {
    position: relative;
    width: calc(100% - 250px);
    height: 100vh;
    min-height: 939px;
    background-color: rgb(43, 92, 220);
}

.about-layout-container {
    position: relative;
    top: 3%;
    padding: 1% 2%;
    width: 100%;
    height: 97%;
    background-color: #e5e5e5;
    border: 0;
    border-top-left-radius: 20px;
}

.about-layout-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.about-layout-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5%;
}

.about-layout-title {
    position: relative;
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    margin: auto 0;
    width: auto;
    height: 25px;
    border-left: 4px solid #236BF3;
}

.about-layout-title-cn {
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: auto;
    height: 100%;
    font-size: 22px;
    font-weight: bolder;
    color: black;
}

.about-layout-title-en {
    display: flex;
    /*align-items: flex-end;*/
    padding-left: 10px;
    width: auto;
    height: 100%;
    font-size: 18px;
    color: rgba(139, 139, 160, 1);
}

.about-layout-account {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
}

.about-layout-main {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 66%;
    height: calc(100% - 50px);
    min-height: 720px;
    max-height: 820px;
}

.about-layout-brief {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 3%;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 20px;
    background-color: white;
    overflow-y: scroll;
}

.about-item-container {
    margin: 0 0 5% 0;
    width: 100%;
    height: auto;
}

.about-item-title {
    margin-bottom: 1%;
    font-size: 16px;
    font-weight: bolder;
    color: #2F3241;
    line-height: 25px;
}

.about-item-content {
    font-size: 14px;
    font-weight: 400;
    color: #2F3241;
    line-height: 30px;
    white-space: pre-line;
}

.about-item-sub-title {
    margin-bottom: 1%;
    font-size: 14px;
    font-weight: bold;
    color: #2F3241;
    line-height: 20px;
}

.about-item-sub-content {
    font-size: 14px;
    font-weight: 500;
    color: #727682;
    line-height: 30px;
    white-space: pre-line;
}

.about-layout-msg {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 0 0 2%;
    width: 32%;
    height: 50%;
}

.about-layout-download {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 20px;
    padding: 2% 8%;
    width: 100%;
    height: 40%;
    min-height: 180px;
    border: 0;
    border-radius: 20px;
    background-color: white;
}

.about-layout-download-title {
    font-size: 18px;
    font-weight: bolder;
    color: #2F3241;
    line-height: 26px;
}

.about-layout-download-wh {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 5%;
    width: 100%;
    height: 50%;
    background: #F8FAFF;
    border-radius: 10px;
    cursor: pointer;
}

.about-layout-download-wh-text {
    width: 70%;
    font-size: 12px;
    font-weight: 500;
    color: #1859DE;
    line-height: 20px;
}

.about-layout-download-vi {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 5%;
    width: 100%;
    height: 30%;
    background: #FFFAF5;
    border-radius: 10px;
    cursor: pointer;
}

.about-layout-download-vi-text {
    width: 70%;
    font-size: 12px;
    font-weight: 500;
    color: #DA8B2F;
    line-height: 20px;
}

.about-layout-website {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 5% 8%;
    width: 100%;
    height: 40%;
    min-height: 140px;
    border: 0;
    border-radius: 20px;
    background: url("../../assets/img/about_website.png") no-repeat center / cover;
    object-fit: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.about-layout-website-title {
    height: 20%;
    font-size: 18px;
    font-weight: bolder;
    color: white;
}

.about-layout-website-text {
    margin-top: 10px;
    height: 40%;
    font-size: 14px;
    color: white;
    white-space: pre-wrap;
}

.about-layout-website-button {
    width: 140px;
    height: 40px;
    border: 0;
    border-radius: 15px;
    background: white;
    font-size: 12px;
    font-weight: bold;
    color: #467CCB;
    cursor: pointer;
}

/* mobile */
.mobile-about-banner {
    z-index: 1;
    width: 100%;
    height: 400px;
}

.mobile-about-wrap {
    z-index: 10;
    position: relative;
    margin: -300px auto 0;
    width: 100%;
    height: auto;
}

.mobile-about-item-title {
    margin: 0 auto;
    width: 90%;
    height: 20px;
    font-size: 16px;
    font-weight: bolder;
    color: #FFFFFF;
    line-height: 20px;
}

.mobile-about-item {
    margin: 20px auto 20px;
    padding: 20px 20px;
    width: 90%;
    height: auto;
    background-color: #ffffff;
    border: 0;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 400;
    color: #3D4B62;
    line-height: 26px;
    letter-spacing: 1px;
    white-space: pre-line;
}

.mobile-about-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    width: 90%;
    height: 50px;
    background: #337AF0;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 48px;
}

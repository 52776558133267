@import '~antd/dist/antd.css';

/* layout */
.promotion-layout {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    min-width: 1250px;
    min-height: 640px;
    max-width: 1440px;
    font-family: MicrosoftYaHei;
}

/* header */
.promotion-header {
    /*position: fixed;*/
    position: relative;
    z-index: 10;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 50px;
    min-width: 1250px;
    max-width: 1920px;
    background: rgb(28, 52, 93);
}

.promotion-header-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 80%;
    height: 100%;
    min-width: 900px;
    /*background-color: white;*/
    line-height: initial;
}

.promotion-header-nav-logo {
    display: flex;
    align-items: center;
    width: 40%;
    height: 100%;
}

.promotion-header-nav-logo-img {
    width: 30px;
    height: 30px;
    /*border-radius: 50%;*/
    /*background-color: white;*/
}

.promotion-header-nav-logo-text {
    display: flex;
    align-items: center;
    margin: 0 0 0 2%;
    width: 80%;
    height: 100%;
    font-size: 14px;
    color: white;
}

.promotion-header-nav-function {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 40%;
    height: 100%;
}

.promotion-header-nav-function-personal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 100%;
    font-size: 14px;
    color: white;
    cursor: pointer;
}

.promotion-header-nav-function-personal-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    width: auto;
    height: 15px;
}

.promotion-header-nav-function-login {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 3%;
    width: auto;
    height: 35%;
    font-size: 14px;
    color: white;
    border-right: 1px solid white;
    cursor: pointer;
}

.promotion-header-nav-function-register {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 3%;
    width: auto;
    height: 100%;
    font-size: 14px;
    color: white;
    cursor: pointer;
}

.promotion-language-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    width: 100px;
    height: 35px;
}

.promotion-language-box:hover {
    background-color: rgba(43, 81, 138, 0.5);
}

.promotion-language-box:focus {
    background-color: rgba(43, 81, 138, 0.5);
}

.promotion-language-item {
    text-align: center;
    background-color: rgba(30, 67, 130, 0.5);
    color: white;
}

.promotion-language-item:hover {
    background-color: rgba(101, 107, 116, 0.7);
    color: white;
}

.promotion-language-item:focus {
    background-color: rgba(101, 107, 116, 0.7);
    color: white;
}

/* wrapper */
.promotion-wrapper {
    width: 100%;
    height: auto;
    min-width: 1250px;
    max-width: 1920px;
}

.promotion-wrapper-banner {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 650px;
}

.promotion-wrapper-banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering: -o-crisp-edges;         /* Opera */
    image-rendering: -webkit-crisp-edges; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
}

.promotion-wrapper-banner-content {
    position: absolute;
    top: 150px;
    left: calc(50% - (65% / 2));
    text-align: center;
    width: 65%;
    height: auto;
    white-space: break-spaces;
}

.promotion-wrapper-banner-content-slogan {
    font-size: 40px;
    font-weight: bolder;
    color: #FFFFFF;
}

.promotion-wrapper-banner-content-slogan-detail {
    padding-top: 20px;
    font-size: 22px;
    line-height: 50px;
    color: #D5DFEB;
}

.promotion-wrapper-banner-content-private-button {
    margin: 5% 0 ;
    padding: 2% 4%;
    width: auto;
    height: auto;
    font-size: 16px;
    border: 0;
    border-radius: 50px;
    background-color: #1162c2;
    color: white;
    cursor: pointer;
}

.promotion-wrapper-purchase {
    width: 100%;
    height: 800px;
    /*background: #8e9eab;  !* fallback for old browsers *!*/
    /*background: -webkit-linear-gradient(to right, #eef2f3, #8e9eab);  !* Chrome 10-25, Safari 5.1-6 *!*/
    /*background: linear-gradient(to right, #eef2f3, #8e9eab); !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/
    background: url("../../assets/img/promotion_process_banner.png") no-repeat center / cover;
    object-fit: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.promotion-wrapper-purchase-banner {
    z-index: 2;
    margin: -105px auto;
    width: 80%;
    height: 700px;
    min-width: 900px;
    border-radius: 20px;
}

.promotion-wrapper-purchase-table {
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 2% 4%;
    width: 100%;
    height: 35%;
    border-radius: 20px;
    box-shadow: 0 0 20px #c2c2c2;
    background-color: white;
}

.promotion-wrapper-purchase-table-title {
    display: flex;
    align-items: center;
    width: auto;
    height: 15%;
    font-size: 20px;
    font-weight: bolder;
}

.promotion-wrapper-purchase-table-rule {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 1% 0 2%;
    width: auto;
    height: 10%;
}

.promotion-wrapper-purchase-table-rule-one {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 80%;
    font-size: 14px;
    color: gray;
}

.promotion-wrapper-purchase-table-rule-two {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1%;
    padding: 0 1%;
    width: auto;
    height: auto;
    line-height: 16px;
    font-size: 14px;
    color: gray;
    border-left: 1px solid rgba(128, 128, 128, 0.8);
    border-right: 1px solid rgba(128, 128, 128, 0.8);
}

.promotion-wrapper-purchase-table-rule-link {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: auto;
    height: 80%;
    font-size: 14px;
    color: #1d3dc9;
    cursor: pointer;
}

.icon-style-other {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
    width: 17px;
    height: 17px;
    object-fit: contain;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.promotion-wrapper-purchase-table-channel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40%;
}

.promotion-wrapper-purchase-table-channel-grid {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    padding: 0 2%;
    width: 35%;
    height: 100%;
    background-color: #F7F8FB;
    border: 0;
    border-radius: 15px;
}

.promotion-wrapper-purchase-table-channel-grid-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30%;
    color: gray;
}

.promotion-wrapper-purchase-table-channel-grid-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 70%;
    height: 45%;
}

.promotion-wrapper-purchase-table-channel-grid-content-num {
    display: flex;
    align-items: flex-end;
    width: auto;
    height: 100%;
    line-height: initial;
    font-size: 18px;
    font-weight: 550;
    color: gray;
}

.promotion-private-input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 0;
    background: #F7F8FB;
}

.promotion-wrapper-purchase-table-channel-grid-content-num-pay {
    display: flex;
    align-items: flex-end;
    width: auto;
    height: 100%;
    line-height: initial;
    font-size: 18px;
    font-weight: 550;
    color: gray;
}

.promotion-wrapper-purchase-table-channel-grid-content-text {
    display: flex;
    align-items: flex-end;
    padding-left: 3%;
    width: 30%;
    height: 100%;
    line-height: initial;
    font-size: 12px;
    color: gray;
}

.promotion-wrapper-purchase-table-channel-grid-currency {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 45%;
    background-color: white;
    border: 0;
    border-radius: 15px;
    font-size: 14px;
    color: black;
}

.promotion-currency-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.promotion-wrapper-purchase-table-channel-grid-symbol {
    width: 2%;
    font-size: 30px;
    color: #123efc;
}

.promotion-wrapper-purchase-table-channel-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 2%;
    width: 20%;
    height: 100%;
    border: 0;
    border-radius: 15px;
    background: #2D6DF0;  /* fallback for old browsers */
    font-size: 20px;
    color: white;
    cursor: pointer;
}

.promotion-wrapper-purchase-process {
    width: 100%;
    height: 70%;
}

.promotion-wrapper-purchase-process-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 6% 0 0;
    width: auto;
    height: 15%;
    font-size: 24px;
    font-weight: bolder;
    letter-spacing: 3px;
    color: #333333;
}

.promotion-wrapper-purchase-process-title-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 2% 0 4%;
}

.promotion-wrapper-purchase-process-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70%;
}

.promotion-wrapper-purchase-process-table-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 23%;
    height: 90%;
    border: 0;
    border-radius: 15px;
    background-color: #FFFFFF;
}

.promotion-wrapper-purchase-process-table-element-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    /*background: #C9D6FF;  !* fallback for old browsers *!*/
    /*background: -webkit-linear-gradient(to right, #E2E2E2, #C9D6FF);  !* Chrome 10-25, Safari 5.1-6 *!*/
    /*background: linear-gradient(to right, #E2E2E2, #C9D6FF); !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/
    font-size: 50px;
    color: #1162c2;
}

.promotion-wrapper-purchase-process-table-element-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 40%;
}

.promotion-wrapper-purchase-process-table-element-title {
    text-align: center;
    white-space: nowrap;
    margin-top: 10px;
    padding-bottom: 5%;
    width: auto;
    font-size: 18px;
    font-weight: bold;
}

.promotion-wrapper-purchase-process-table-element-des {
    text-align: center;
    font-size: 12px;
    color: gray;
}

.promotion-wrapper-about {
    display: table;
    width: 100%;
    height: 500px;
    background: url("../../assets/img/promotion_intr_bg.png") no-repeat center / cover;
    object-fit: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.promotion-wrapper-about-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    width: 80%;
    height: 100%;
    min-width: 900px;
}

.promotion-wrapper-about-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    height: 60%;
}

.promotion-wrapper-about-img {
    margin-left: 10%;
    width: 40%;
    height: 60%;
}

.promotion-wrapper-about-content-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 10%;
    font-size: 24px;
    font-weight: bolder;
    letter-spacing: 3px;
    color: white;
}

.promotion-wrapper-about-content-title-bg {

}

.promotion-wrapper-about-content-des {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30%;
    line-height: 30px;
    font-size: 14px;
    letter-spacing: 1px;
    color: white;
}

.promotion-wrapper-about-content-download {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 48%;
    height: 15%;
    border: 0;
    border-radius: 10px;
    background-color: #2041ce;
    font-size: 12px;
    color: white;
    cursor: pointer;
}

.promotion-wrapper-about-content-download-icon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

.promotion-wrapper-contact {
    width: 100%;
    height: 420px;
    /*background: #8e9eab;  !* fallback for old browsers *!*/
    /*background: -webkit-linear-gradient(to right, #eef2f3, #8e9eab);  !* Chrome 10-25, Safari 5.1-6 *!*/
    /*background: linear-gradient(to right, #eef2f3, #8e9eab); !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/
    background: url("../../assets/img/promotion_process_banner.png") no-repeat center / cover;
    object-fit: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.promotion-wrapper-contact-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 4% 0 0;
    width: auto;
    height: 10%;
    font-size: 24px;
    font-weight: bolder;
    letter-spacing: 3px;
    color: #333333;
}

.promotion-wrapper-contact-title-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 2% 0 4%;
}

.promotion-wrapper-contact-way {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;
    width: 80%;
    height: 40%;
    min-width: 900px;
    background-color: white;
    border: 0;
    border-radius: 20px;
}

.promotion-wrapper-contact-way-element {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 60%;
}

.promotion-wrapper-contact-way-element-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 10px;
    font-size: 32px;
    color: #3c3cc9;
}

.promotion-wrapper-contact-way-element-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 5%;
    width: 40%;
    height: 60%;
}

.promotion-wrapper-contact-way-element-title {
    white-space: nowrap;
    font-size: 14px;
    color: gray;
}

.promotion-wrapper-contact-way-element-des {
    white-space: nowrap;
    font-size: 14px;
    color: black;
}

/* 弹窗 */
.promotion-modal {
    z-index: 20;
    position: absolute;
    width: 100%;
    height: 2615px;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
}

.promotion-modal-win {
    z-index: 20;
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    top: 30%;
    left: 30%;
    width: 40%;
    height: 40%;
    background: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
    border-radius: 20px;
}

.promotion-modal-win-close {
    position: fixed;
    top: 29%;
    left: 70%;
    padding-left: 1%;
    width: auto;
    height: auto;
    border: 0;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    font-size: 26px;
    color: white;
}

/* 登录弹窗 */
.promotion-modal-login-win {
    z-index: 20;
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    /*align-content: center;*/
    /*justify-content: space-evenly;*/
    top: calc(50% - 200px);
    left: calc(50% - 190px);
    padding: 20px 0;
    width: 380px;
    height: 400px;
    background: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
    border-radius: 20px;
    white-space: pre-wrap;
}

.promotion-modal-login-win-welcome {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto;
    width: 80%;
    height: 20%;
    font-size: 22px;
    font-weight: bolder;
}

.promotion-modal-login-win-login {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding-top: 5%;
    width: 80%;
    height: 80%;
    font-size: 14px;
    color: gray;
}

.promotion-modal-login-win-login-element {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 20%;
}

.promotion-modal-login-win-login-text {
    display: flex;
    align-items: center;
    width: 20%;
    height: 80%;
}

.promotion-modal-login-win-login-input {
    display: flex;
    align-items: center;
    width: 80%;
    height: 70%;
    border-radius: 5px;
    border: 1px solid #4b64d0;
}

input:focus {
    outline: none;
}

.promotion-modal-login-win-login-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    width: 100%;
    height: 15%;
    border: 0;
    border-radius: 5px;
    background-color: #1162c2;
    color: white;
    cursor: pointer;
}

.promotion-modal-login-win-login-other {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 10%;
}

.promotion-modal-login-win-login-qr {
    font-size: 14px;
    color: #123efc;
    cursor: pointer;
}

.promotion-modal-login-win-login-pf {
    font-size: 14px;
    color: gray;
}

.promotion-modal-login-win-close {
    position: fixed;
    top: calc(50% - 210px);
    left: calc(50% + 190px);
    padding-left: 1%;
    width: auto;
    height: auto;
    transform: scale(0.8);
    border: 0;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    font-size: 28px;
    color: white;
}

/* 注册弹窗 */
.promotion-modal-register-win {
    z-index: 20;
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    top: calc(50% - 225px);
    left: calc(50% - 200px);
    padding: 20px 0;
    width: 400px;
    height: 450px;
    background: #FFFFFF;
    box-shadow: 0 8px 16px 0 rgba(119, 119, 119, 0.2);
    border-radius: 10px;
}

.promotion-modal-register-win-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    width: 90%;
    height: 15%;
}

.promotion-modal-register-win-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 85%;
    height: 85%;
}

.promotion-modal-register-win-element {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 15%;
}

.promotion-modal-register-win-text {
    display: flex;
    align-items: center;
    width: 15%;
    height: 70%;
    color: gray;
}

.text-justify {
    /*text-align-last: justify;*/
    text-align-last: justify;
    width: 100%;
}

.promotion-modal-register-win-input {
    display: flex;
    align-items: center;
    width: 80%;
    height: 70%;
    border-radius: 5px;
    border: 1px solid #4b64d0;
}

.promotion-modal-register-win-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5% auto 2%;
    width: 100%;
    height: 12%;
    border: 0;
    border-radius: 5px;
    background-color: #1859DE;
    color: white;
    cursor: pointer;
}

.promotion-modal-register-win-other {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 10%;
}

.promotion-modal-register-win-other-left {
    font-size: 12px;
    color: #666666;
}

.promotion-modal-register-win-other-right {
    font-size: 12px;
    font-weight: bold;
    color: #1859DE;
    cursor: pointer;
}

.promotion-modal-register-win-close {
    position: fixed;
    top: 19%;
    left:  calc(50% + 25% / 2);
    padding-left: 1%;
    width: auto;
    height: auto;
    border: 0;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    font-size: 28px;
    color: white;
}

/* 认购规则弹窗 */
.modal-rule-win {
    z-index: 20;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    top: calc(50% - 175px);
    left: calc(50% - 300px);
    padding: 20px 0;
    width: 600px;
    height: 350px;
    background: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
    border-radius: 20px;
    white-space: pre-wrap;
}

.modal-rule-win-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15%;
    font-size: 18px;
    font-weight: bolder;
    color: #333333;
    line-height: 35px;
}

.modal-rule-win-text {
    /*display: flex;*/
    /*justify-content: flex-start;*/
    /*align-items: center;*/
    margin: 10px auto 0;
    width: 90%;
    height: 80%;
    font-size: 14px;
    color: #666666;
    line-height: 30px;
    overflow-y: scroll;
    white-space: pre-wrap;
}

.modal-rule-win-close {
    position: fixed;
    top: calc(50% - 180px);
    left: calc(50% + 300px);
    padding-left: 1%;
    width: auto;
    height: auto;
    border: 0;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    font-size: 28px;
    color: white;
    transform: scale(0.8);
}

/* mobile */
.mobile-promotion-layout {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
}

.mobile-promotion-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    width: 100%;
    height: 80px;
    /*background: rgb(28, 52, 93);*/
    background-color: white;
}

.mobile-promotion-header-logo {
    display: flex;
    align-items: center;
    padding-left: 3%;
    width: 50%;
    height: 100%;
}

.mobile-promotion-header-nav-logo-img {
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
}

.mobile-promotion-header-nav-logo-text {
    display: flex;
    align-items: center;
    margin: 0 0 0 3%;
    width: auto;
    height: 100%;
    font-size: 16px;
    color: white;
}

.mobile-promotion-header-lr {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 1%;
    width: 40%;
    height: 100%;
}

.mobile-promotion-header-button {
    width: 70%;
    height: 40%;
    max-width: 150px;
    max-height: 60px;
    border: 0;
    border-radius: 20px;
    background-color: #2949d2;
    color: white;
}

.mobile-menu-top {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 100%;
    border: 0;
    /*background: rgba(22, 81, 153, 0.5);*/
    font-size: 26px;
    color: white;
}

.mobile-catalog-win {
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
}

.mobile-promotion-wrapper {
    width: 100%;
    height: auto;
}

.mobile-promotion-wrapper-banner {
    z-index: 1;
    width: 100%;
    height: 400px;
}

.mobile-promotion-wrapper-banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.mobile-promotion-wrapper-banner-content {
    position: relative;
    top: -330px;
    text-align: center;
    margin: 0 auto;
    width: 85%;
    height: auto;
}

.mobile-promotion-wrapper-banner-content-slogan {
    font-size: 22px;
    font-weight: bolder;
    color: white;
}

.mobile-promotion-wrapper-banner-content-slogan-detail {
    padding-top: 2%;
    line-height: 30px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.9);
}

.mobile-promotion-wrapper-banner-content-private-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto 0 ;
    width: 50%;
    height: auto;
    max-width: 180px;
    max-height: 45px;
    line-height: 50px;
    font-size: 14px;
    border: 0;
    border-radius: 30px;
    background-color: #2468F2;
    color: white;
}

.mobile-promotion-wrapper-purchase {
    width: 100%;
    height: 440px;
    background: #8e9eab;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #eef2f3, #8e9eab);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #eef2f3, #8e9eab); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.mobile-promotion-wrapper-purchase-table {
    z-index: 10;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: -80px auto;
    padding: 4% 5%;
    width: 90%;
    height: 90%;
    max-height: 370px;
    border-radius: 20px;
    box-shadow: 0 0 20px #c2c2c2;
    background-color: white;
}

.mobile-promotion-wrapper-purchase-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;
}

.mobile-promotion-wrapper-purchase-table-header-title {
    font-size: 16px;
    font-weight: bold;
    color: black;
}

.mobile-promotion-wrapper-purchase-table-header-rule {
    font-size: 14px;
    color: #2468F2;
}

.mobile-promotion-wrapper-purchase-table-price {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    padding: 0 5%;
    width: 100%;
    height: 15%;
    border: 0;
    border-radius: 15px;
    background-color: #F8FAFF;
}

.mobile-price-icon {
    margin-right: 2%;
    font-size: 14px;
    color: blue;
}

.mobile-price-bold {
    font-size: 12px;
    font-weight: bold;
    color: black;
}

.mobile-price-gray {
    font-size: 12px;
    color: gray;
}

.mobile-promotion-wrapper-purchase-table-input {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 40%;
}

.mobile-promotion-wrapper-purchase-table-input-element {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5%;
    width: 100%;
    height: 40%;
    border-radius: 15px;
    border: 1px solid rgba(128, 128, 128, 0.5);
}

.mobile-input-element-text {
    width: 25%;
    font-size: 12px;
    color: gray;
}

.mobile-input-element-num {
    width: 45%;
    font-size: 14px;
    font-weight: bold;
}

.mobile-input-element-currency {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 60%;
    border: 0;
    border-radius: 15px;
    background-color: rgba(161, 161, 161, 0.1);
}

.mobile-input-element-currency-one {
    font-size: 14px;
    color: #123efc;
}

.mobile-input-element-currency-two {
    font-size: 14px;
    color: #13a613;
}

.mobile-promotion-wrapper-purchase-table-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 50px;
    border: 0;
    border-radius: 5px;
    background-color: #2468F2;
    font-size: 16px;
    color: white;
}

.mobile-promotion-wrapper-about {
    width: 100%;
    height: 700px;
    background: url("../../assets/img/promotion_about_bg_m.png") no-repeat center / cover;
    object-fit: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.mobile-promotion-wrapper-about-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 50px 0 80px;
    width: 90%;
    height: 100%;
    max-width: 600px;
}

.mobile-promotion-wrapper-about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 5% 0;
    width: 100%;
    height: 40%;
}

.mobile-promotion-wrapper-about-content-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
    font-size: 20px;
    font-weight: bolder;
    color: white;
}

.mobile-promotion-wrapper-about-content-title-en {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    width: 100%;
    height: 10%;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
}

.mobile-promotion-wrapper-about-content-des {
    text-align: center;
    margin: 10% auto;
    width: 80%;
    height: 40%;
    line-height: 30px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
}

.mobile-promotion-wrapper-about-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 5% 0;
    width: 80%;
    height: 40%;
}

.img-size-contain {
    width: 100%;
    height: 100%;
    object-fit: contain;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.mobile-promotion-wrapper-about-content-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 25%;
}

.mobile-promotion-wrapper-about-content-download {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5% 0;
    width: 100%;
    height: 40%;
    /*max-width: 450px;*/
    min-height: 50px;
    border: 0;
    border-radius: 10px;
    background-color: #337AF0;
    font-size: 14px;
    color: white;
}

.mobile-promotion-wrapper-contact {
    width: 100%;
    height: 500px;
    background: #F8FBFF;  /* fallback for old browsers */
}

.mobile-promotion-wrapper-contact-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 50px 0;
    width: 80%;
    height: 100%;
}

.mobile-promotion-wrapper-contact-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5%;
    font-size: 20px;
    font-weight: bolder;
    color: black;
}

.mobile-promotion-wrapper-contact-title-en {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5%;
    font-size: 14px;
    color: gray;
}

.mobile-promotion-wrapper-contact-way {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 80%;
    /*background-color: white;*/
    border: 0;
    border-radius: 20px;
}

.mobile-promotion-wrapper-contact-way-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2% 0;
    width: 130px;
    height: 130px;
    background: #FFFFFF;
}

.mobile-promotion-wrapper-contact-way-element-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border: 1px solid #3c3cc9;
    border-radius: 10px;
    font-size: 24px;
    color: #3c3cc9;
}

.mobile-promotion-wrapper-contact-way-element-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45%;
}

.mobile-promotion-wrapper-contact-way-element-title {
    font-size: 14px;
    color: gray;
}

.mobile-promotion-wrapper-contact-way-element-des {
    font-size: 14px;
    color: black;
}

.mobile-promotion-footer {
    width: 100%;
    height: auto;
    background: rgb(28, 52, 93);
}

.mobile-promotion-footer-collapse {
    background: rgb(28, 52, 93);

}

.ant-collapse-header {
    font-size: 16px;
    color: white !important;
}

.ant-collapse-icon-position-right {
    color: white;
}

.ant-collapse-item {
    border: 0 !important;
}

.mobile-promotion-footer-panel {
    height: auto;
    background: rgb(28, 52, 93);
}

.ant-collapse-content-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*padding-top: 0 !important;*/
    padding: 0 !important;
    background: rgb(45, 73, 124);
}

.mobile-promotion-footer-panel-p {
    display: flex;
    align-items: center;
    margin: 0 !important;
    padding-left: 40px;
    height: 50px;
    font-size: 12px;
    color: gray;
}

.mobile-promotion-footer-copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: #232323;
    font-size: 12px;
    color: gray;
}

.mobile-rule-link {
    position: relative;
    text-align: center;
    margin: 100px auto;
    width: 23%;
    height: auto;
    color: #1d3dc9;
    font-size: 14px;
    line-height: 30px;
    border-bottom: 1px solid #1d3dc9;
}

.ant-dropdown-menu {
    /*z-index: 99;*/
    /*padding: 0 !important;*/
    /*margin: 5px 0 0 0;*/
    /*width: 100% !important;*/
    /*height: auto !important;*/
    /*!*max-width: 249px !important;*!*/
    /*min-height: 40px;*/
    /*max-height: 250px !important;*/
    /*border-radius: 5px !important;*/
    /*background-color: rgba(255, 255, 255, 1);*/
    /*overflow-x: hidden;*/
}

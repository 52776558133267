/* account */
.account-layout {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    min-width: 1280px;
    max-width: 2200px;
}

.account-layout-wrapper {
    position: relative;
    width: calc(100% - 250px);
    height: 100vh;
    min-height: 939px;
    background-color: rgb(43, 92, 220);
}

.account-layout-container {
    position: relative;
    top: 3%;
    padding: 1% 2%;
    width: 100%;
    height: 97%;
    background-color: #e5e5e5;
    border: 0;
    border-top-left-radius: 20px;
}

.account-layout-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.account-layout-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5%;
}

.account-layout-title {
    position: relative;
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    margin: auto 0;
    width: auto;
    height: 25px;
    border-left: 4px solid #236BF3;
}

.account-layout-title-cn {
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: auto;
    height: 100%;
    font-size: 22px;
    font-weight: bolder;
    color: black;
}

.account-layout-title-en {
    display: flex;
    /*align-items: flex-end;*/
    padding-left: 10px;
    width: auto;
    height: 100%;
    font-size: 18px;
    color: rgba(139, 139, 160, 1);
}

.account-layout-account {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
}

.account-layout-main {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 92%;
}

.account-layout-base {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 2% 2%;
    width: 100%;
    height: 240px;
    background: #FFFFFF;
    border-radius: 10px;
}

.account-layout-main-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 15%;
    font-size: 18px;
    font-weight: bolder;
    color: #333333;
    line-height: 26px;
}

.account-layout-main-ele {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
    width: 100%;
    height: 70%;
    background: #F9F9FB;
    border: 0;
    border-radius: 10px;
}

.account-layout-main-ele-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    width: 95%;
    height: 100px;
}

.account-layout-main-ele-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 88%;
}

.account-layout-main-ele-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
}

.icon-img {
    width: 100%;
    height: 100%;
}

.account-layout-main-ele-title {
    padding-left: 10px;
    width: 10%;
    min-width: 100px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
}

.account-layout-main-ele-title-m {
    /*float: right;*/
    display: flex;
    flex-wrap: nowrap;
    width: auto;
    font-size: 12px;
    font-weight: 400;
    color: #236BF3;
    line-height: 22px;
    word-break: break-all;
    cursor: pointer;
}

.account-layout-main-ele-text {
    padding-right: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
}

.account-layout-safety {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 2% 2%;
    width: 100%;
    height: 180px;
    background: #FFFFFF;
    border-radius: 10px;
}

.account-layout-verify {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 2% 2%;
    width: 100%;
    height: 310px;
    background: #FFFFFF;
    border-radius: 10px;
}

.account-layout-logout {
    width: 100%;
    height: 50px;
}

.account-layout-logout-button {
    margin: 0 0 20px;
    width: 250px;
    height: 40px;
    background: #236BF3;
    border: 0;
    border-radius: 6px;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 22px;
    cursor: pointer;
}

/* ver */
.account-layout-ver {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2% 2%;
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border-radius: 10px;
}

.account-layout-ver-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 5%;
    font-size: 18px;
    font-weight: bolder;
    color: #333333;
    line-height: 26px;
}

.account-layout-ver-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2%;
    width: 100%;
    height: 60%;
    background: #F9F9FB;
    border: 0;
    border-radius: 10px;
}

.account-layout-ver-ele {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    width: 95%;
    height: 100px;
}

.account-layout-ver-ele-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
}

.account-layout-ver-ele-title {
    padding-left: 10px;
    width: 90%;
    min-width: 100px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
}

.account-layout-ver-ele-title-m {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 8%;
    font-size: 14px;
    font-weight: 400;
    color: #236BF3;
    line-height: 22px;
}

.account-layout-ver-confirm {
    margin-top: 2%;
    width: 100%;
    height: 50px;
}

.account-layout-ver-button {
    margin: 30px 0 0;
    width: 280px;
    height: 45px;
    background: #236BF3;
    border: 0;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF;
    cursor: pointer;
}

/* vGo */
.account-layout-vgo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2% 2%;
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border-radius: 10px;
}

.account-layout-vgo-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 5%;
    font-size: 18px;
    font-weight: bolder;
    color: #333333;
    line-height: 26px;
}

.account-layout-vgo-abstract {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    width: 100%;
    height: 5%;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
}

.account-layout-vgo-ele {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 30px auto 0;
    width: 100%;
    height: auto;
}

.account-layout-vgo-ele-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    background: #337AF0;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 26px;
}

.account-layout-vgo-ele-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    width: 90%;
    height: auto;
}

.account-layout-vgo-ele-title {
    width: 100%;
    height: auto;
    font-size: 16px;
    font-weight: bolder;
    color: #0D1E3D;
    line-height: 26px;
}

.account-layout-vgo-ele-des {
    margin: 10px 0;
    width: 100%;
    height: auto;
    font-size: 14px;
    font-weight: 400;
    color: #4A5563;
    line-height: 26px;
}

.account-layout-vgo-ele-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: auto;
    height: 45px;
}

.vgo-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
    width: 190px;
    height: 34px;
    background: #236BF3;
    border: 0;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 22px;
    cursor: pointer;
}

.vgo-button-modify {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 70%;
}

.account-layout-vgo-ele-img {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 420px;
    height: 158px;
    background: #F7F8FB;
    border-radius: 16px;
}

.qr-img {
    width: 130px;
    height: 130px;
    background: #FFFFFF;
    border-radius: 5px;
}

.account-layout-vgo-ele-img-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 55%;
    height: 80%;
}

.account-layout-vgo-ele-seKey {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 400;
    color: #4A5563;
    line-height: 50px;
}

.input-empty {
    font-size: 14px;
    border: 0;
    background: #F7F8FB;
}

.account-layout-vgo-ele-seKey-copy {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 400;
    color: #236BF3;
    line-height: 20px;
    cursor: pointer;
}

.account-layout-vgo-ele-input {
    display: flex;
    align-items: center;
    margin: 10px 0 20px;
    width: 420px;
    height: 45px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #D9E0E9;
    font-size: 14px;
    color: #666666;
}

.account-layout-vgo-ele-input-button {
    cursor: pointer;
    border-radius: 5px;
    width: 35%;
    height: 100%;
    border: 0;
    color: white;
}

.modal-win-send-button-mod {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 15px;
    border-left: 1px solid #F0F0F0;
    cursor: pointer;
}

/* bind */
.account-layout-bind {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2% 2%;
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border-radius: 10px;
}

.account-layout-bind-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 5%;
    font-size: 18px;
    font-weight: bolder;
    color: #333333;
    line-height: 26px;
}

.account-layout-bind-ele {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 15px 0 0 0;
    width: 25%;
    height: 80px;
}

.account-layout-bind-ele-input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60%;
    border-radius: 5px;
    border: 1px solid #D9E0E9;
    font-size: 14px;
}

.account-layout-bind-ele-input-notice {
    margin-bottom: 5px;
    width: 25%;
    height: 10px;
    color: red;
    font-size: 12px;
    line-height: 20px;
}

.account-layout-bind-ele-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 280px;
    height: 45px;
    background: #236BF3;
    border: 0;
    border-radius: 8px;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 20px;
    cursor: pointer;
}

.account-layout-bind-ele-vgo-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 456px;
    height: 38px;
    background: #236BF3;
    border: 0;
    border-radius: 6px;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 20px;
    cursor: pointer;
}

/* modify */
.account-layout-modify {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2% 2%;
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border-radius: 10px;
}

.account-layout-modify-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 5%;
    font-size: 16px;
    font-weight: bolder;
    color: #333333;
    line-height: 26px;
}

.input-style-common {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 280px;
    height: 40px;
    border: 0;
}

.account-layout-modify-ele {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 15px 0 0 0;
    width: 25%;
    height: 80px;
}

.account-layout-modify-ele-input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60%;
    border-radius: 5px;
    border: 1px solid #D9E0E9;
    font-size: 14px;
}

.account-layout-modify-ele-input::-webkit-input-placeholder {
    font-size: 12px;
}

.account-layout-modify-ele-input::-moz-placeholder {
    font-size: 12px;
}

.account-layout-modify-ele-input::-moz-placeholder {
    font-size: 12px;
}

.account-layout-modify-ele-input input[type='password'] {
    font-size: 12px;
}

.account-layout-modify-ele-input-notice {
    margin-top: 5px;
    width: 25%;
    height: 15px;
    color: red;
    font-size: 12px;
    line-height: 20px;
}

/* mobile */
.mobile-account-layout-main {
    margin: 0 auto;
    width: 90%;
    height: auto;
}

.mobile-account-title {
    display: flex;
    flex-direction: row;
    font-size: 22px;
    font-weight: bolder;
    color: #0D1E3D;
    line-height: 54px;
}

.mobile-account-sub-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #0D1E3D;
}

.mobile-account-ele {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 5% 0;
    padding: 0 5%;
    width: 100%;
    height: 60px;
    background: #F9F9FB;
    border: 0;
    border-radius: 6px;
}

.mobile-account-ele-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 5% 0 0;
    width: 100%;
    height: 60px;
    background: #F9F9FB;
    border: 0;
    border-radius: 6px;
}

.mobile-account-ele-title {
    font-size: 14px;
    font-weight: 600;
    color: #0D1E3D;
    line-height: 46px;
}

.mobile-account-ele-text {
    font-size: 12px;
    font-weight: 400;
    color: #3D4B62;
    line-height: 40px;
}

.mobile-account-button {
    margin: 5% 0;
    width: 100%;
    height: 60px;
    border: 0;
    border-radius: 15px;
    background: #337AF0;
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 48px;
}

/*.ant-input-affix-wrapper {*/
/*    border: 0;*/
/*}*/

.mobile-account-vgo-ele {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 30px auto 0;
    width: 100%;
    height: auto;
}

.mobile-account-vgo-ele-label {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0 0;
    width: 22px;
    height: 22px;
    background: #337AF0;
    border-radius: 50%;
    font-size: 18px;
    color: #FFFFFF;
}

.mobile-account-vgo-ele-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    width: 90%;
    height: auto;
}

.mobile-account-vgo-ele-title {
    width: 100%;
    height: auto;
    font-size: 14px;
    font-weight: bolder;
    color: #0D1E3D;
    line-height: 26px;
}

.mobile-account-vgo-ele-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0 0;
    width: 100%;
    height: 40px;
}

.mobile-vgo-button {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 45%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #D9DEE6;
    font-size: 12px;
    color: #337AF0;
}

.mobile-account-vgo-ele-key {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #D9E0E9;
    border-radius: 10px;
}

.mobile-account-vgo-ele-input {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    width: 70%;
    height: 100%;
    border: 0;
    border-radius: 10px;
    font-size: 12px;
    color: #0D1E3D;
}

.mobile-account-vgo-ele-input-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 15px;
    border-left: 1px solid #D9DEE6;
    font-size: 12px;
    color: #337AF0;
}

.mobile-send-button {
    z-index: 10;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 8%;
    width: 80px;
    height: 15px;
    border-left: 1px solid #F0F0F0;
    font-size: 12px;
    cursor: pointer;
}

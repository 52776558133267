/* footer */
.footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 300px;
}

.footer-wrapper {
    display: table;
    width: 100%;
    height: 80%;
}

.footer-container {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    background-color: #060d38;
}

.footer-container-area {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 80%;
    height: 75%;
    min-width: 900px;
}

.footer-container-logo {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 35%;
    height: 100%;
}

.footer-container-logo-img {
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
}

.footer-container-logo-text {
    display: flex;
    align-items: center;
    margin: 0 0 0 2%;
    width: 80%;
    height: auto;
    line-height: 30px;
    font-size: 14px;
    color: white;
}

.footer-container-link-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 65%;
    height: 100%;
}

.footer-container-link {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 40px;
    width: auto;
    height: 100%;
    min-width: 20%;
    max-width: 33%;
}

.footer-container-link-title {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: auto;
    height: auto;
    line-height: 40px;
    font-size: 16px;
    color: white;
}

.footer-container-link-con {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-container-link-con-text {
    white-space: nowrap;
    line-height: 24px;
    font-size: 12px;
    color: gray;
    cursor: pointer;
}

.footer-copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
    background-color: #232323;
    font-size: 14px;
    color: white;
}

.mobile-footer {
    width: 100%;
    height: auto;
    background: rgb(28, 52, 93);
}

.mobile-footer-collapse {
    background: rgb(28, 52, 93);

}

.ant-collapse-header {
    font-size: 16px;
    color: white !important;
}

.ant-collapse-icon-position-right {
    color: white;
}

.ant-collapse-item {
    border: 0 !important;
}

.mobile-footer-panel {
    height: auto;
    background: rgb(28, 52, 93);
}

.mobile-footer-panel-div {
    display: flex;
    align-items: center;
    height: 49px;
    background: rgb(28, 52, 93);
}

.ant-collapse-content-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*padding-top: 0 !important;*/
    padding: 0 !important;
    background: rgb(45, 73, 124);
}

.mobile-footer-panel-p {
    display: flex;
    align-items: center;
    margin: 0 !important;
    padding-left: 40px;
    height: 50px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
}

.mobile-footer-copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: #232323;
    font-size: 12px;
    color: gray;
}

/* rewards */
.rewards-layout {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    min-width: 1280px;
    max-width: 2200px;
}

.rewards-layout-wrapper {
    position: relative;
    width: calc(100% - 250px);
    height: 100vh;
    min-height: 939px;
    background-color: rgb(43, 92, 220);
}

.rewards-layout-container {
    position: relative;
    top: 3%;
    padding: 1% 2%;
    width: 100%;
    height: 97%;
    background-color: #e5e5e5;
    border: 0;
    border-top-left-radius: 20px;
}

.rewards-layout-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.rewards-layout-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5%;
}

.rewards-layout-title {
    position: relative;
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    margin: auto 0;
    width: auto;
    height: 25px;
    border-left: 4px solid #236BF3;
}

.rewards-layout-title-cn {
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: auto;
    height: 100%;
    font-size: 22px;
    font-weight: bolder;
    color: black;
}

.rewards-layout-title-en {
    display: flex;
    /*align-items: flex-end;*/
    padding-left: 10px;
    width: auto;
    height: 100%;
    font-size: 18px;
    color: rgba(139, 139, 160, 1);
}

.rewards-layout-account {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
}

.rewards-layout-banner {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    width: 100%;
    height: 110px;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
    background: url("../../assets/img/rewards_bg.png") no-repeat center / contain;
    object-fit: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.img-layout {
    height: 100%;
    width: 100%;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.rewards-layout-banner-container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    width: 50%;
    height: 50%;
}

.rewards-layout-banner-container-title {
    font-size: 18px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 40px;
}

.rewards-layout-banner-container-content {
    font-size: 14px;
    color: #D3E0F9;
}

.rewards-layout-banner-button-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rewards-layout-banner-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 200px;
    height: 40px;
    border: 0;
    border-radius: 20px;
    background: #1C4FBE;
    font-size: 16px;
    color: #FFFFFF;
}

.rewards-layout-my-code {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2% 2%;
    width: 100%;
    height: 160px;
    background: #FFFFFF;
    border-radius: 10px;
}

.rewards-layout-my-code-title {
    position: relative;
    width: 100%;
    height: 30%;
    font-size: 18px;
    font-weight: bolder;
    color: #2F3241;
    line-height: 26px;
}

.rewards-layout-my-code-content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 70%;
}

.rewards-layout-my-code-ele {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25%;
    height: 100%;
}

.rewards-layout-my-code-ele-title {
    display: flex;
    align-items: flex-end;
    width: auto;
    height: 35%;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
}

.rewards-layout-my-code-ele-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    width: 100%;
    height: 45px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #D9E0E9;
}

.rewards-layout-my-code-ele-content-reg {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    width: 100%;
    height: 45px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #D9E0E9;
}

.rewards-layout-my-code-ele-content-share {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    width: 100%;
    height: 45px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #D9E0E9;
}

.rewards-layout-my-code-ele-content-text {
    overflow: hidden;
    text-align: left;
    padding-left: 5%;
    width: 70%;
    font-size: 12px;
    color: #2F3241;
    line-height: 18px;
}

.rewards-layout-my-code-ele-content-input {
    z-index: -99;
    position: absolute;
    left: 0;
    bottom: 50px;
    opacity: 0;
    width: 1%;
}

.rewards-layout-my-code-ele-content-copy {
    text-align: center;
    width: 22%;
    background-color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    color: #236BF3;
    line-height: 12px;
    border: 0;
    border-left: 1px solid #F0F0F0;
    cursor: pointer;
}

.rewards-layout-my-code-button-ele {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 22%;
    height: 100%;
}

.rewards-layout-my-code-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    background-color: #1859DE;
    border: 0;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 22px;
    cursor: pointer;
}

.download-img {
    padding-right: 10px;
    transform: scale(0.8);
}

.rewards-layout-my-assets {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2%;
    padding: 2% 2%;
    width: 100%;
    height: 180px;
    border: 0;
    border-radius: 20px;
    background-color: white;
}

.rewards-layout-assets-title {
    position: relative;
    width: 100%;
    height: 15%;
    font-size: 18px;
    font-weight: bolder;
    color: #2F3241;
    line-height: 26px;
}

.rewards-layout-assets-content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 1% 0;
    width: 100%;
    height: 60%;
}

.rewards-layout-assets-element {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 30%;
    height: 100%;
    border-radius: 10px;
}

.rewards-layout-assets-element-icon {
    width: 50px;
    height: 50px;
}

.rewards-layout-assets-element-icon-img {
    width: 100%;
    height: 100%;
}

.rewards-layout-assets-element-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
    height: 50%;
}

.rewards-layout-assets-element-title {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 400;
    color: #707A8A;
    line-height: 18px;
}

.rewards-layout-assets-element-num {
    font-size: 22px;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bolder;
    color: #2F3241;
    line-height: 32px;
}

.rewards-layout-record {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2% 0;
    width: 100%;
    height: auto;
    border: 0;
    border-radius: 20px;
    background-color: white;
}

.rewards-layout-record-title {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 2%;
    width: 100%;
    height: 5%;
    font-size: 18px;
    font-weight: bolder;
    color: #2F3241;
    line-height: 26px;
}

.rewards-layout-record-table-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.rewards-layout-record-empty {
    width: 178px;
    height: 170px;
    overflow: hidden;
}

.rewards-layout-record-empty-text {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
}


/* mobile */
.mobile-rewards-layout {
    width: 100%;
    height: auto;
    background-color: #ffffff;
}

.mobile-rewards-wrap {
    width: 100%;
    height: auto;
    background-color: #F9F9FB;
}

.mobile-rewards-banner {
    z-index: 1;
    width: 100%;
    height: 400px;
}

.mobile-rewards-table {
    z-index: 10;
    position: relative;
    margin: -100px auto 0;
    width: 90%;
    height: 500px;
    background-color: #ffffff;
    border: 0;
    border-radius: 15px;
}

.mobile-rewards-table-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    background: #FFF5DE;
    border-radius: 15px 15px 0 0;
    opacity: 0.86;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 32px;
}

.mobile-rewards-table-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5%;
    width: 100%;
    height: 440px;
}

.mobile-rewards-table-main-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #0D1E3D;
}

.mobile-rewards-table-main-ele {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
}

.mobile-rewards-table-main-ele-title {
    font-size: 12px;
    font-weight: 400;
    color: #777777;
    line-height: 38px;
}

.mobile-rewards-table-main-ele-input-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1% 1%;
    width: 100%;
    height: 50%;
    border: 1px solid #dadada;
    border-radius: 10px;
}

.mobile-rewards-table-main-ele-input-container-reg {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1% 1%;
    width: 100%;
    height: 50%;
    border: 1px solid #dadada;
    border-radius: 10px;
}

.mobile-rewards-table-main-ele-input-container-share {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1% 1%;
    width: 100%;
    height: 50%;
    border: 1px solid #dadada;
    border-radius: 10px;
}

.mobile-rewards-table-main-ele-input {
    display: flex;
    align-items: center;
    margin-left: 5%;
    width: 75%;
    height: 60%;
    border: 0;
}

.mobile-rewards-table-main-ele-copy {
    width: 25%;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 42px;
    background-color: #FFFFFF;
    border: 0;
}

.mobile-rewards-table-down {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
}

.mobile-rewards-table-down-qr {
    width: 50px;
    height: 50px;
}

.mobile-rewards-down-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(80% - 20px);
    height: 50px;
    background: #DF5060;
    border: 0;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 48px;
}

.mobile-rewards-assets {
    z-index: 10;
    position: relative;
    margin: 40px auto;
    width: 90%;
    height: 150px;
}

.mobile-rewards-assets-title {
    font-size: 18px;
    font-weight: bolder;
    color: #2F3241;
    line-height: 26px;
}

.mobile-rewards-assets-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px auto 0;
    width: 100%;
    height: 90%;
    background: #FFFFFF;
    border-radius: 15px;
}

.mobile-rewards-assets-ele {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33%;
    height: 100%;
}

.mobile-rewards-assets-ele-num {
    font-size: 24px;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    color: #0D1E3D;
    line-height: 50px;
}

.mobile-rewards-assets-ele-title {
    font-size: 12px;
    font-weight: 400;
    color: #777777;
    line-height: 34px;
}

.mobile-rewards-record {
    margin: 40px auto;
    width: 100%;
    height: auto;
}

.mobile-rewards-record-title {
    display: flex;
    justify-content: flex-start;
    padding-top: 20px;
    width: 100%;
}

.mobile-policy-title {
    padding-left: 20px;
    font-size: 22px;
    font-weight: 600;
    color: #0D1E3D;
    line-height: 54px;
}

.mobile-policy-content {
    padding: 0 20px;
    font-size: 14px;
    font-weight: 400;
    color: #3D4B62;
    line-height: 40px;
    white-space: pre-wrap;
}

.mobile-fail-img {
    margin: 0 auto;
    width: 120px;
    height: 120px;
}

.mobile-fail-title {
    text-align: center;
    width: auto;
    font-size: 22px;
    font-weight: 600;
    color: #0D1E3D;
    line-height: 48px;
}

.mobile-fail-content {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #666666;
    line-height: 40px;
}

.mobile-fail-contact {
    margin: 0 auto;
    padding: 20px;
    width: 90%;
    height: auto;
    background: #F9F9FB;
    border-radius: 10px;
}

.mobile-fail-contact-wrap {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 5px;
}

.mobile-fail-contact-ele {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    width: 90%;
    height: 50px;
}

.mobile-fail-contact-ele-text {
    width: 40%;
    font-size: 14px;
    font-weight: 400;
    color: #9EA4B0;
    line-height: 40px;
}

.mobile-fail-contact-ele-info {
    width: 60%;
    font-size: 14px;
    font-weight: bold;
    color: #0D1E3D;
    line-height: 40px;
}

.mobile-fail-contact-ele-line {
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right, #ccc 0%, #ccc 50%, transparent 50%);
    background-size: 10px 4px;
    background-repeat: repeat-x;
}

.mobile-qr-title {
    margin: 0 0 20px 0;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #0D1E3D;
    line-height: 46px;
}


.mobile-qr-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 200px;
    height: 200px;
    background: #F9F9FB;
    border-radius: 15px;
}

.mobile-qr-tip {
    margin: 20px 0 0 0;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #777777;
    line-height: 20px;
}

.mobile-qr-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto;
    width: 250px;
    height: 50px;
    background: #337AF0;
    border: 0;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
}

.mobile-rewards-qr-img-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.mobile-rewards-qr-img {
    z-index: 20;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    width: 310px;
    height: 550px;
    background: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
    border-radius: 20px;
    white-space: pre-wrap;
}

.canvas-style {
    z-index: -99;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.mobile-img-qr-bg {
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 17%;
    top: 560px;
    width: 85px;
    height: 62px;
    background: #FFFFFF;
}

.mobile-img-qr {
    z-index: 99;
    /*right: 11%;*/
    /*top: 528px;*/
    transform: scale(0.45);
}

.mobile-rewards-qr-tip {
    text-align: left;
    font-size: 12px;
    color: #DF5060;
    line-height: 18px;
}

.mobile-rewards-qr-img-function {
    z-index: 99;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 25px auto 0;
    padding: 8px 5px;
    width: 310px;
    height: 80px;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 0 5px #8f8f8f;
}

.mobile-rewards-function {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 35px;
    height: 35px;
}

.mobile-rewards-function-ele {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: calc(100% - 80px);
}

.mobile-rewards-function-text {
    text-align: center;
    font-size: 16px;
    font-weight: bolder;
    color: #3D4B62;
    line-height: 30px;
}

.mobile-rewards-download {
    width: 100%;
    height: 100%;
}

.mobile-rewards-close {
    width: 90%;
    height: 90%;
    background: rgba(117, 117, 117, 0.8);
    border-radius: 50%;
}

.img-size-close {
    width: 100%;
    height: 100%;
    object-fit: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    transform: scale(0.4);
}

.page-layout {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    max-width: 1440px;
    max-height: 1000px;
    min-width: 1250px;
    min-height: 640px;
}

.page-wrapper {
    position: relative;
    width: calc(100% - 250px);
    height: 100%;
    background-color: rgb(43, 92, 220);
}

.page-container {
    position: relative;
    top: 3%;
    padding: 1% 1%;
    width: 100%;
    height: 97%;
    background-color: #F7F8FB;
    border: 0;
    border-top-left-radius: 20px;
}

.page-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    margin: 0 0;
    padding: 0 1%;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    height: 50px;
}

.page-title {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: auto 0;
    width: 85%;
    height: 25px;
    border-left: 4px solid #236BF3;
}

.page-title-cn {
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: auto;
    height: 100%;
    font-size: 22px;
    font-weight: bolder;
    color: black;
}

.page-title-en {
    display: flex;
    /*align-items: flex-end;*/
    padding-left: 10px;
    width: auto;
    height: 100%;
    font-size: 18px;
    color: rgba(139, 139, 160, 1);
}

.page-language {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 10px;
    width: 100px;
    height: 30px;
}

.page-language-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /*padding: 0;*/
    width: 100%;
    height: 100%;
    border: 1px solid gray;
    font-size: 12px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 1);
}

.page-language-box:hover {
    font-weight: bolder;
}

.page-language-box:focus {
    font-weight: bolder;
}

.page-language-item {
    width: 100%;
    text-align: center;
    background-color: rgba(255, 255, 255, 1);
    color: #000000;
}

.page-language-item:hover {
    width: 100%;
    text-align: center;
    font-weight: bold;
    background-color: rgb(196, 196, 196);
    color: #000000;
}

.page-language-item:focus {
    width: 100%;
    text-align: center;
    font-weight: bold;
    background-color: rgb(196, 196, 196);
    color: #000000;
}

.page-account {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
}

.page-account-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 18px;
}

.page-account-username {
    padding-left: 5px;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 400;
    line-height: 20px;
    color: #2F3241;
}

.page-main-layout {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: auto;
    overflow-y: scroll;
}

/* mobile */
.mobile-page-layout {
    width: 100%;
    height: auto;
    background: #FFFFFF;
}

.mobile-page-wrapper {
    z-index: 1;
    width: 100%;
    height: auto;
}

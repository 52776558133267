/* overview */
.overview-layout {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-width: 1280px;
  max-width: 2200px;
}

.overview-layout-wrapper {
  position: relative;
  width: calc(100% - 250px);
  height: 100vh;
  min-height: 939px;
  background-color: rgb(43, 92, 220);
}

.overview-layout-container {
  position: relative;
  top: 3%;
  padding: 1% 2%;
  width: 100%;
  height: 97%;
  background-color: #e5e5e5;
  border: 0;
  border-top-left-radius: 20px;
}

.overview-layout-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.overview-layout-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 5%;
}

.overview-layout-title {
  position: relative;
  display: flex;
  flex-direction: row;
  /*align-items: center;*/
  margin: auto 0;
  width: auto;
  height: 25px;
  border-left: 4px solid #236BF3;
}

.overview-layout-title-cn {
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: auto;
  height: 100%;
  font-size: 22px;
  font-weight: bolder;
  color: black;
}

.overview-layout-title-en {
  display: flex;
  /*align-items: flex-end;*/
  padding-left: 10px;
  width: auto;
  height: 100%;
  font-size: 18px;
  color: rgba(139, 139, 160, 1);
}

.overview-layout-account {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
}

.overview-layout-main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 70%;
  height: calc(100% - 50px);
  min-height: 720px;
  max-height: 820px;
}

.overview-layout-assets {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto 20px;
  padding: 20px 3% 30px;
  width: 100%;
  height: 25%;
  border: 0;
  border-radius: 15px;
  background-color: white;
}

.overview-layout-assets-title {
  position: relative;
  width: 100%;
  height: 30%;
  font-size: 18px;
  font-weight: bolder;
}

.overview-layout-assets-content {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 60%;
}

.overview-layout-assets-element {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
}

.overview-layout-assets-element-title {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 30%;
}

.overview-layout-assets-element-title-pot {
  position: relative;
  width: 10px;
  height: 10px;
  border: 0;
  border-radius: 50%;
}

.overview-layout-assets-element-title-base {
  position: relative;
  padding: 0 5px;
  font-size: 12px;
  color: gray;
}

.overview-layout-assets-element-title-text {
  position: relative;
  font-size: 12px;
  color: gray;
}

.overview-layout-assets-element-num {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 90%;
  height: 70%;
  border-bottom: 2px solid #E9E9F4;;
}

.overview-layout-assets-element-num-num {
  display: flex;
  align-items: center;
  width: auto;
  height: 100%;
  font-size: 20px;
  font-weight: bolder;
}

.overview-layout-assets-element-num-percent {
  position: relative;
  top: 2px;
}

.overview-layout-private {
  position: relative;
  width: 100%;
  height: 75%;
  padding: 20px 3% 20px;
  border: 0;
  border-radius: 15px;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  background: url("../../assets/img/overview_private_bg.png") no-repeat center / cover;
  object-fit: cover;
}

.overview-layout-private-title {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 8%;
  font-size: 18px;
  font-weight: bolder;
}

.overview-layout-private-table {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  width: 50%;
  height: 90%;
  min-width: 400px;
  border: 0;
  border-radius: 20px;
  /*background-color: #8e9eab;*/
}

.overview-layout-private-table-tip {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2% 3%;
  width: 100%;
  height: 20%;
  border-radius: 10px;
  border: 1px dashed #D9E0E9;
  background-color: #F8FAFF;
}

.overview-layout-private-table-tip-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: #d5dcf5;
  border: 0;
  border-radius: 10px;
  font-size: 24px;
  color: #123efc;
}

.overview-layout-private-table-tip-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding-left: 5%;
  width: 85%;
  height: 80%;
}

.overview-layout-private-table-tip-title {
  display: flex;
  align-items: center;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  color: black;
}

.overview-layout-private-table-tip-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
}

.overview-layout-private-table-tip-sub-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 20px;
  font-size: 12px;
  color: #1859DE;
  cursor: pointer;
}

.overview-layout-private-table-tip-text {
  display: flex;
  flex-direction: row;
  width: 100%;
  line-height: 20px;
  font-size: 12px;
  color: gray;
}

.text-style {
  display: flex;
  flex-direction: row;
}

.overview-layout-private-table-form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 5% 3%;
  width: 100%;
  height: 75%;
  border: 0;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 12px 0 rgba(186, 186, 186, 0.14)
}

.overview-layout-private-table-form-element {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin: 0 auto;
  width: 80%;
  height: 30%;
}

.overview-layout-private-table-form-title {
  width: 100%;
  height: 20%;
  font-size: 12px;
  color: #666666;
}

.overview-layout-private-table-form-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1% 1%;
  width: 100%;
  height: 45px;
  border: 1px solid #dadada;
  border-radius: 5px;
}

.overview-layout-private-table-form-input {
  display: flex;
  align-items: center;
  margin-left: 5%;
  width: 75%;
  height: 60%;
  border: 0;
  background: #FFFFFF;
}

.overview-layout-private-table-form-currency {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  width: 25%;
  height: 90%;
  border: 0;
  border-radius: 5px;
  background-color: rgba(127, 154, 239, 0.3);
  font-size: 12px;
  font-weight: 400;
}

.icon-style {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  object-fit: contain;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.currency-title {
  text-align: center;
  width: 35px;
}

.overview-layout-private-table-form-button {
  margin: 10px auto;
  width: 80%;
  height: 15%;
  border: 0;
  border-radius: 5px;
  background-color: #1859DE;
  font-size: 14px;
  color: white;
  cursor: pointer;
}

.overview-layout-private-table-form-note {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 15%;
  font-size: 12px;
  word-break: break-all;
}

.note-style {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: auto;
  font-size: 12px;
}

.overview-layout-msg {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 20px 2%;
  width: 28%;
  height: calc(100% - 50px);
  min-height: 720px;
  max-height: 820px;
}

.overview-layout-banner {
  position: relative;
  width: 100%;
  height: 47%;
  border: 0;
  border-radius: 15px;
  overflow: hidden;
  background: #e0e1f5 no-repeat 50% 50%;
}

.overview-layout-banner-slogan {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 0 auto;
  width: 80%;
  height: 20%;
  font-size: 20px;
  font-weight: bolder;
  color: #3c68fa;
}

.overview-layout-banner-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  height: 30%;
  line-height: 26px;
  font-size: 14px;
  font-weight: 500;
  color: gray;
}

.overview-layout-invitation {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5%;
  width: 100%;
  height: 12%;
  border: 0;
  border-radius: 15px;
  background-color: white;
  background-size: cover;
  background-image: url("/src/assets/img/overview_my_inviter.png");
  background-repeat: no-repeat;
  background-position: center -12px;
  cursor: pointer;
}

.overview-layout-invitation-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-start;
  height: 80%;
}

.overview-layout-invitation-title {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bolder;
  color: black;
}

.overview-layout-invitation-text {
  display: flex;
  align-items: center;
  line-height: 20px;
  font-size: 12px;
  color: gray;
}

.overview-layout-official {
  position: relative;
  padding: 5%;
  width: 100%;
  height: 35%;
  border: 0;
  border-radius: 15px;
  background-color: white;
}

.overview-layout-official-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
}

.overview-layout-official-title {
  font-size: 16px;
  font-weight: bolder;
  color: black;
}

.overview-layout-official-list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 5% auto;
  width: 100%;
  height: 80%;
}

.overview-layout-official-text {
  display: flex;
  align-items: center;
  height: 25%;
  font-size: 12px;
}

/* mobile */
.mobile-overview-layout {
  width: 100%;
  height: auto;
}

.mobile-overview-wrapper {
  width: 100%;
  height: auto;
}

.mobile-overview-banner {
  z-index: 1;
  width: 100%;
  height: 380px;
}

.mobile-overview-banner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.mobile-overview-banner-content {
  position: relative;
  top: -320px;
  text-align: center;
  margin: 0 auto;
  width: 85%;
  height: auto;
}

.mobile-overview-banner-content-slogan {
  line-height: 28px;
  font-size: 22px;
  font-weight: bolder;
  color: white;
  text-align: center;
  letter-spacing: 2px;
}

.mobile-overview-banner-content-slogan::after {
  display: inline-block;
  width: 100%;
  content: "";
}

.mobile-overview-banner-content-slogan-detail {
  padding-top: 2%;
  line-height: 30px;
  font-size: 14px;
  color: #FFFFFF;
}

.mobile-overview-banner-content-private-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0 ;
  width: 50%;
  height: auto;
  max-width: 180px;
  max-height: 45px;
  line-height: 50px;
  font-size: 14px;
  border: 0;
  border-radius: 40px;
  background-color: #2468F2;
  color: white;
}

.mobile-overview-purchase {
  width: 100%;
  height: 400px;
  background: #F9F9FB;
}

.mobile-overview-purchase-table {
  z-index: 10;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: -60px auto;
  padding: 5% 5%;
  width: 90%;
  height: 95%;
  border-radius: 20px;
  box-shadow: 0 0 20px #c2c2c2;
  background-color: white;
}

.mobile-overview-purchase-table-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.mobile-overview-purchase-table-header-title {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.mobile-overview-purchase-table-header-rule {
  font-size: 14px;
  color: blue;
}

.mobile-overview-purchase-label-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  margin: 10px auto 0;
  width: 80%;
  height: 80px;
}

.mobile-overview-purchase-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  height: auto;
  opacity: 0.5;
}

.mobile-overview-purchase-label-selected {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  height: auto;
}

.mobile-overview-purchase-label-index-selected {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 14px;
  font-weight: 600;
  color: #FFFFFF;
  background: url("../../assets/icon/icon_overview_label_sel.svg") no-repeat center / contain;
  object-fit: cover;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.mobile-overview-purchase-label-index {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #282828;
  background: url("../../assets/icon/icon_overview_label.svg") no-repeat center / contain;
  object-fit: cover;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.mobile-overview-purchase-label-text-selected {
  text-align: center;
  width: 50px;
  font-size: 12px;
  font-weight: 400;
  color: #236BF3;
  line-height: 33px;
}

.mobile-overview-purchase-label-text {
  text-align: center;
  width: 50px;
  font-size: 12px;
  font-weight: 400;
  color: #282828;
  line-height: 33px;
}

.mobile-overview-purchase-label-line {
  position: relative;
  top: -15px;
  width: 10%;
  height: 1px;
  border: 1px dashed #D9E0E9;
}

.mobile-overview-purchase-table-price {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px auto;
  padding: 0 5%;
  width: 100%;
  height: 50px;
  border: 0;
  border-radius: 5px;
  background-color: #F8FAFF;
}

.mobile-price-icon {
  margin-right: 2%;
  width: 16px;
  /*font-size: 14px;*/
  /*color: blue;*/
}

.mobile-price-bold {
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  color: black;
}

.mobile-price-gray {
  white-space: nowrap;
  font-size: 12px;
  color: #3D4B62;
}

.mobile-overview-purchase-table-input {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.mobile-overview-purchase-table-input-element {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5%;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid rgba(128, 128, 128, 0.5);
}

.mobile-input-element-text {
  width: 30%;
  font-size: 12px;
  color: gray;
}

.mobile-input-element-num {
  padding-left: 5px;
  width: 50%;
  font-size: 14px;
  font-weight: bold;
  border: 0;
  background: #FFFFFF;
}

.mobile-input-element-currency {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 30%;
  height: 70%;
  border: 0;
  border-radius: 10px;
  background: #ECF2FE;
}

.mobile-input-element-currency-one {
  text-align: center;
  width: 40px;
  font-size: 12px;
  font-weight: 800;
  color: #5A1D7A;
}

.mobile-input-element-currency-two {
  text-align: center;
  width: 40px;
  font-size: 12px;
  font-weight: 800;
  color: #13a613;
}

.mobile-input-notice {
  display: flex;
  align-items: center;
  margin: 5px 0;
  width: 100%;
  height: 15px;
  color: red;
  font-size: 12px;
  line-height: 20px;
  word-break: break-all;
}

.mobile-click-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 50px;
  border: 0;
  border-radius: 5px;
  background-color: #337AF0;
  color: white;
  cursor: pointer;
}

.mobile-overview-purchase-table-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 15%;
  border: 0;
  border-radius: 15px;
  background-color: #337AF0;
  font-size: 16px;
  color: white;
}

.mobile-overview-assets {
  width: 100%;
  height: 180px;
  background-color: #F9F9FB;
}

.mobile-overview-assets-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  width: 95%;
  height: 100%;
}

.mobile-overview-assets-layout {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 2%;
  width: 100%;
  height: 50px;
}

.mobile-overview-assets-title {
  /*padding-left: 2%;*/
  /*height: 20%;*/
  font-size: 16px;
  font-weight: 600;
  color: #0D1E3D;
  line-height: 50px;
}

.mobile-overview-assets-rule-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
}

.mobile-overview-assets-rule {
  position: relative;
  text-align: center;
  width: auto;
  height: auto;
  color: #1d3dc9;
  font-size: 12px;
  line-height: 30px;
  border-bottom: 1px solid #1d3dc9;
}

.mobile-overview-assets-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 60%;
}

.mobile-overview-assets-element {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*margin: 1%;*/
  padding: 5% 1%;
  width: 32%;
  height: calc(93vw / 3);
  /*min-width: 100px;*/
  background: #FFFFFF;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.16);
}

.mobile-overview-assets-element-pot {
  width: 18px;
  height: 18px;
  box-shadow: 0px 4px 8px 0px rgba(116, 191, 114, 0.3);
  border-radius: 16px;
  border: 2px solid #FFFFFF;
}

.mobile-overview-assets-element-num {
  margin-top: 10px;
  width: 100%;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #0D1E3D;
}

.mobile-overview-assets-element-text {
  display: flex;
  flex-wrap: nowrap;
  width: auto;
  font-size: 12px;
  color: #777777;
}

.mobile-overview-center {
  padding: 20px 0;
  width: 100%;
  height: 190px;
  background-color: #F9F9FB;
}

.mobile-overview-center-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  width: 90%;
  height: 100%;
}

.mobile-overview-center-title {
  height: 30%;
  font-size: 16px;
  font-weight: 600;
  color: #0D1E3D;
  line-height: 50px;
}

.mobile-overview-center-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5%;
  width: 100%;
  height: 60%;
  background: #FFFFFF;
  border-radius: 16px;
}

.mobile-overview-center-icon {
  width: 50px;
  height: 50px;
}

.mobile-img {
  width: 100%;
  height: 100%;
}

.mobile-overview-center-content-title {
  padding-left: 20px;
  width: calc(100% - 80px);
}

.mobile-overview-center-content-title-main {
  font-size: 16px;
  font-weight: bolder;
  color: #0D1E3D;
  letter-spacing: 1px;
}

.mobile-overview-center-content-title-sub {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #777777;
}

.mobile-overview-center-arrow {
  width: 30px;
  height: 30px;
}

.mobile-overview-invite {
  padding: 0 0 20px;
  width: 100%;
  height: 320px;
  background-color: #F9F9FB;
}

.mobile-overview-invite-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  width: 90%;
  height: 100%;
}

.mobile-overview-invite-title {
  height: 20%;
  font-size: 16px;
  font-weight: 600;
  color: #0D1E3D;
  line-height: 50px;
}

.mobile-overview-invite-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 80%;
}

.mobile-overview-invite-img {
  width: 100%;
  height: 115px;
}

.pot-small-size {
  margin-right: 10px;
  width: 6px;
  height: 6px;
  background: rgba(38,104,242,0.5);
  border: 0;
  border-radius: 50%;
}

.pot-big-size {
  margin-right: 10px;
  width: 8px;
  height: 8px;
  background: #2668F2;
  border: 0;
  border-radius: 50%;
}

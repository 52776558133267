@import '~antd/dist/antd.css';

/*滚动条*/
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(68, 67, 67, 0);
    /*border-radius:10px;*/
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #5a76cd;
}

:focus {
    outline: none;
    border: 0;
}

.layout {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    font-family: MicrosoftYaHei;
    background: #aeb6be;
}

.ant-layout-content {
}

/* nav */
.nav-layout {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 250px;
    height: 100%;
    min-height: 640px;
    background-color: #1859DE;
}

.nav-logo {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 20% 0 15%;
    width: 80%;
    height: 10%;
}

.nav-logo-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 0;
    border-radius: 50%;
}

.nav-logo-img {
    position: relative;
    width: 100%;
    height: 100%;
}

.nav-logo-text {
    position: relative;
    width: calc(90% - 30px);
    height: auto;
    line-height: 25px;
    font-size: 16px;
    text-transform: uppercase;
    color: white;
}

.ant-layout-header {
    padding: 0;
}


/* menu */
.nav-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: auto;
}

.nav-menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10%;
    margin-bottom: 8px;
    width: 100%;
    height: 66px;
    font-weight: lighter;
    color: white;
}

.nav-menu-item:hover {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
    width: 100%;
    height: 66px;
    background: rgba(231, 231, 231, 0.2);
    font-weight: bold;
    color: white;
}

.nav-menu-item-icon {
    display: flex;
    justify-content: center;
    width: 30%;
    font-size: 20px;
    color: white;
}

.nav-menu-item-title {
    display: flex;
    justify-content: flex-start;
    width: 70%;
    line-height: 18px;
    font-size: 14px;
    cursor: pointer;
}

.nav-empty {
    position: relative;
    width: 100%;
    height: calc(35% - 60px);
}

/* language */
.nav-language {
    position: relative;
    /*top: 300px;*/
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
    width: 80%;
}

.nav-language-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /*padding: 0;*/
    width: 60px;
    height: 60px;
    background-color: rgba(43, 81, 138, 0.2);
    border: 0;
    border-radius: 0;
}

.nav-language-box:hover {
    background-color: rgba(43, 81, 138, 0.5);
}

.nav-language-box:focus {
    background-color: rgba(43, 81, 138, 0.5);
}

.nav-language-item {
    text-align: center;
    background-color: rgba(30, 67, 130, 0.5);
    color: white;
}

.nav-language-item:hover {
    text-align: center;
    background-color: rgba(101, 107, 116, 0.7);
    color: white;
}

.nav-language-item:focus {
    text-align: center;
    background-color: rgba(101, 107, 116, 0.7);
    color: white;
}

/* mobile */
.mobile-nav-wrap {
    z-index: 99;
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    background: #FFFFFF;
}

.mobile-nav-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 1% 0;
    width: 92%;
    height: 80px;
    background: transparent;
}

.mobile-nav-layout-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 60%;
}

.mobile-nav-layout-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 40%;
}

.mobile-nav-layout-logo {
    width: 30px;
    height: 30px;
}

.mobile-img {
    width: 100%;
    height: 100%;
}

.mobile-nav-layout-name {
    margin-left: 10px;
    width: auto;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bolder;
    color: #0D1E3D;
    line-height: 45px;
    white-space: nowrap;
}

.mobile-nav-layout-button {
    width: 100px;
    height: 32px;
    border: 0;
    border-radius: 20px;
    background-color: #337AF0;
    font-size: 14px;
    color: white;
}

.mobile-nav-layout-lang {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    width: 100px;
    background: #F1F4FB;
    border-radius: 27px;
    border: 1px solid rgba(255, 255, 255, 0.16);
    font-size: 14px;
    font-weight: 400;
    color: #3D4B62;
    line-height: 30px;
}

.mobile-nav-layout-login {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 2px;
    padding: 0 4px;
    width: 50px;
    background: #337AF0;
    border-radius: 5px;
    font-size: 14px;
    line-height: 30px;
    color: #FFFFFF;
}

.mobile-nav-layout-menu {
    /*position: fixed;*/
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 100%;
    border: 0;
    /*background: rgba(22, 81, 153, 0.5);*/
    font-size: 26px;
    color: #000000;
}

.mobile-nav-language-item  {
    text-align: center;
    background: #F1F4FB;
    color: #3D4B62;
}

.mobile-nav-language-item :hover {
    text-align: center;
    background: #F1F4FB;
    color: #3D4B62;
}

.mobile-nav-language-item :focus {
    text-align: center;
    background: #F1F4FB;
    color: #3D4B62;
}

.mobile-nav-menu {
    position: relative;
    /*top: -1px;*/
    padding: 23px 0 1%;
    width: 100vw;
    height: calc(100vh - 80px);
    min-height: 670px;
    background-color: #ffffff;
}

.mobile-nav-menu-container {
    margin: 0 auto;
    width: 90%;
}

.mobile-nav-menu-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 6%;
    width: 100%;
    height: 150px;
    border: 0;
    border-radius: 10px;
    background-color: #337AF0
}

.mobile-nav-menu-card-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 10%;
}

.mobile-nav-menu-card-title-base {
    width: 30%;
    font-size: 14px;
    color: white;
}

.mobile-nav-menu-card-title-slogan {
    width: 60%;
    font-size: 12px;
    color: white;
}

.mobile-nav-menu-card-login {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
    width: 100%;
    height: 60px;
}

.mobile-nav-menu-card-login-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    /*border: 2px solid white;*/
    border-radius: 50%;
}

.mobile-nav-menu-card-login-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-between;
    margin-left: 10px;
    width: calc(100% - 50px);
    height: 50px;
}

.mobile-nav-menu-card-login-username {
    line-height: 26px;
    font-size: 18px;
    font-weight: 400;
    color: white;
}

.mobile-nav-menu-card-login-id {
    line-height: 26px;
    font-size: 12px;
    color: #e7e7e7;
}

.mobile-nav-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 90%;
    height: 60px;
    font-weight: lighter;
    color: white;
}

.mobile-nav-menu-item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    font-size: 14px;
    color: black;
}

.mobile-nav-menu-item-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    width: calc(100% - 50px);
    font-size: 14px;
    font-weight: bold;
    color: black;
}

.mobile-nav-menu-item-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    font-size: 12px;
    color: gray;
}

.mobile-nav-menu-logout {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    height: 50px;
    border: 0;
    border-radius: 10px;
    background-color: rgba(174, 201, 246, 0.2);
    font-size: 16px;
    color: #465bd0;
}


/* antd dropdown*/
.ant-dropdown-placement-bottomRight {
    min-width: 52px;
    left: 0px;
    top: 80px !important;
}

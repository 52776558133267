/* private */
.private-layout {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    min-width: 1280px;
    max-width: 2200px;
}

.private-layout-wrapper {
    position: relative;
    width: calc(100% - 250px);
    height: 100vh;
    min-height: 939px;
    background-color: rgb(43, 92, 220);
}

.private-layout-container {
    position: relative;
    top: 3%;
    padding: 1% 2%;
    width: 100%;
    height: 97%;
    background-color: #e5e5e5;
    border: 0;
    border-top-left-radius: 20px;
}

.private-layout-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.private-layout-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5%;
}

.private-layout-title {
    position: relative;
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    margin: auto 0;
    width: auto;
    height: 25px;
    border-left: 4px solid #236BF3;
}

.private-layout-title-cn {
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: auto;
    height: 100%;
    font-size: 22px;
    font-weight: bolder;
    color: black;
}

.private-layout-title-en {
    display: flex;
    /*align-items: flex-end;*/
    padding-left: 10px;
    width: auto;
    height: 100%;
    font-size: 18px;
    color: rgba(139, 139, 160, 1);
}

.private-layout-account {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
}

.private-layout-main {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
    height: 50%;
    min-height: 450px;
}

.private-layout-process {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1% 2%;
    width: 70%;
    height: 100%;
    border: 0;
    border-radius: 20px;
    background-color: white;
}

.private-layout-process-title {
    position: relative;
    width: 100%;
    height: 8%;
    font-size: 18px;
    font-weight: bolder;
    color: #2F3241;
    line-height: 26px;
}

.private-layout-process-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 90%;
    height: 85%;
    min-width: 630px;
    border: 0;
    border-radius: 20px;
}

.private-layout-process-timeline {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 90%;
    height: 15%;
    min-width: 630px;
}

.private-layout-process-timeline-ele {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30%;
    font-size: 14px;
    font-weight: 400;
    color: #8B8BA0;
    line-height: 22px;
    word-break: break-all;
}

.private-layout-process-timeline-ele-select {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30%;
    font-size: 14px;
    font-weight: bold;
    color: #236BF3;
    line-height: 22px;
    word-break: break-all;
}

.private-layout-process-timeline-step {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    width: 18px;
    height: 18px;
    background: #FFFFFF;
    border-radius: 50%;
    border: 1px solid;
}

.private-layout-process-timeline-line {
    width: 15%;
    height: 1px;
    border: 1px dashed #D9E0E9;
}

.private-layout-process-table {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;
    width: 280px;
    height: 100%;
}

.private-layout-process-table-form {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin: 0 auto;
    width: 100%;
    height: 60%;
}

.private-layout-process-table-form-element {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin: 0 auto;
    width: 100%;
    height: 50%;
}

.private-layout-process-table-form-title {
    width: 100%;
    height: 20%;
    font-size: 12px;
    color: #484848;
}

.private-layout-process-table-form-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1% 1%;
    width: 100%;
    height: 45px;
    border: 1px solid #dadada;
    border-radius: 10px;
}

.private-layout-process-table-form-input {
    display: flex;
    align-items: center;
    margin-left: 5%;
    width: 75%;
    height: 60%;
    border: 0;
    background: #FFFFFF;
    font-size: 12px;
}

.private-layout-process-table-form-input-notice {
    width: 100%;
    height: 10px;
    color: red;
    font-size: 12px;
    line-height: 20px;
}

.private-layout-process-table-form-currency {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 8px;
    width: 25%;
    height: 90%;
    min-width: 70px;
    border: 0;
    border-radius: 5px;
    background-color: rgba(127, 154, 239, 0.3);
    font-size: 12px;
    font-weight: 400;
}

.private-layout-process-table-form-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 45px;
    border: 0;
    border-radius: 8px;
    background-color: #2947e0;
    line-height: 40px;
    font-size: 14px;
    color: white;
    cursor: pointer;
}

.private-layout-process-table-form-note {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 5%;
    font-size: 12px;
}

.private-layout-process-check-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2% 0;
    margin: 0 auto;
    width: 80%;
    height: 100%;
    min-width: 540px;
}

.private-layout-process-check-tip {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 10%;
    font-size: 12px;
    color: #666666;
    word-break: normal;
}

.private-layout-process-check-tip-text {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    font-size: 12px;
    color: #666666;
    word-break: normal;
}

.private-layout-process-check-table {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60%;
    background: #F8FAFF;
    border-radius: 8px;
}

.private-layout-process-check-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 5%;
    width: 50%;
    height: 80%;
    color: #3a3a3b;
}

.private-layout-process-check-box-ele {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 30%;
    font-size: 12px;
}

.private-layout-process-check-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 280px;
    height: 45px;
    border: 0;
    border-radius: 10px;
    background-color: #2947e0;
    font-size: 12px;
    color: white;
    cursor: pointer;
}

.private-layout-process-finish-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2% 0;
    margin: 0 auto;
    width: 80%;
    height: 100%;
}

.private-layout-process-finish-icon {
    width: 90px;
    height: 90px;
}

.finish-icon {
    width: 100%;
    height: 100%;
}

.private-layout-process-finish-box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 10px 0 0;
    width: 100%;
    height: 50%;
}

.private-layout-process-finish-box-ele {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: auto;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
}

.private-layout-process-finish-box-title {
    width: auto;
    height: 20%;
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    line-height: 22px;
}

.private-layout-process-finish-box-tip {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
}

.private-layout-process-finish-button {
    margin: 0 auto;
    width: 280px;
    height: 45px;
    border: 0;
    border-radius: 8px;
    background-color: #1859DE;
    line-height: 40px;
    font-size: 14px;
    color: white;
    cursor: pointer;
}

.private-layout-note {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1% 2%;
    width: 28%;
    height: 100%;
    border: 0;
    border-radius: 20px;
    background-color: white;
}

.private-layout-note-title {
    position: relative;
    width: 100%;
    height: 5%;
    font-size: 18px;
    font-weight: bolder;
    color: #2F3241;
    line-height: 26px;
}

.private-layout-note-element {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 3% 5%;
    width: 100%;
    height: 25%;
    background: #F8FAFF;
    border-radius: 8px;
}

.private-layout-note-element-unit {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 12px;
    color: #484848;
    line-height: 20px;
}

.private-layout-note-element-num {
    display: flex;
    align-items: center;
    width: auto;
    font-size: 22px;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    color: #2F3241;
}

.private-layout-note-element-currency {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 10px;
    width: auto;
    font-size: 12px;
    font-weight: bold;
    color: #2F3241;
}

.private-layout-note-rule {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 35%;
}

.private-layout-note-rule-text {
    font-size: 14px;
    font-weight: 400;
    color: #1859DE;
    line-height: 20px;
    cursor: pointer;
}

.private-layout-note-rule-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 80%;
}

.img-size {
    width: 100%;
    height: 100%;
    object-fit: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.rule-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.private-layout-msg {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
    height: 850px;
    border: 0;
    border-radius: 20px;
    background-color: white;
}

.private-layout-record {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 20px;
    background-color: white;
}

.private-layout-record-title {
    position: relative;
    width: 100%;
    height: 5%;
    font-size: 18px;
    font-weight: bolder;
    color: #2F3241;
    line-height: 26px;
}

.private-layout-record-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90%;
}

.private-layout-record-table-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.private-layout-record-table {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 600px;
}

/* antd table */
.ant-table-container {
    position: relative;
    width: 100%;
    height: 700px;
}

.ant-table-cell {
    position: relative;
    padding: 10px 10px !important;
    overflow-wrap: break-word;
    height: 60px !important;
}

.ant-space-vertical {
    gap: 0 !important;
}

.ant-table-pagination.ant-pagination {
    /*position: absolute;*/
    /*bottom: -70px;*/
    /*right: 5px;*/
    /*margin: 0 !important;*/
}

.private-layout-record-empty {
    width: auto;
    height: auto;
    transform: scale(0.8);
    overflow: hidden;
}

.private-layout-record-empty-text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
}

/* modal */
.private-modal {
    z-index: 20;
    position: absolute;
    width: calc(100% + 250px);
    height: 103%;
    left: -250px;
    top: -3%;
    background: rgba(0, 0, 0, 0.7);
}

.private-modal-win {
    z-index: 20;
    position: fixed;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    top: calc(50% - 190px);
    left: calc(50% - 250px);
    padding: 2% 0;
    width: 500px;
    height: 380px;
    background: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
    border-radius: 20px;
    white-space: pre-wrap;
}

.poster-img {
    width: 100%;
    height: 100%;
    /*object-fit: cover;*/
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    background-image: url("../../assets/img/rewards_poster_sample.png");
}

.img-qr {
    position: absolute;
    bottom: -5px;
    right: -5px;
    transform: scale(0.45);
}

.private-modal-win-down {
    position: absolute;
    top: calc(50% + 260px);
    left: calc(50% - 155px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
    width: 310px;
    height: 80px;
    border: 0;
    border-radius: 8px;
    background: #2F3241;
    font-size: 14px;
    font-weight: 500;
    line-height: 50px;
    color: #FFFFFF;
    cursor: pointer;
}

.private-modal-win-down-icon {
    width: 30px;
    height: 30px;
}

.private-modal-win-down-text-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 88%;
}

.private-modal-win-down-text {
    font-size: 16px;
    font-weight: bolder;
    color: #FFFFFF;
    line-height: 30px;
}

.private-modal-win-down-text-other {
    text-align: left;
    font-size: 12px;
    color: #DF5060;
    line-height: 14px;
}

.private-modal-win-icon {
    width: 80px;
    height: 80px;
}

.private-modal-win-icon-img {
    width: 100%;
    height: 100%;
}

.private-modal-win-title {
    font-size: 18px;
    font-weight: bolder;
    color: #333333;
    line-height: 25px;
}

.private-modal-win-container {
    margin: 0 auto;
    width: 85%;
    height: 80%;
    overflow-y: scroll;
}

.private-modal-win-container-pay {
    margin: 0 auto;
    width: 85%;
    height: auto;
}

.private-modal-win-container-title {
    font-size: 12px;
    font-weight: 400;
    color: #3a3a3b;
    line-height: 26px;
}

.private-modal-win-container-sub-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.private-modal-win-container-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 5px 20px;
    width: 100%;
    height: 100px;
    background: #F8FAFF;
    border-radius: 8px;
}

.private-modal-win-container-text {
    font-size: 12px;
    font-weight: 400;
    color: #3a3a3b;
    line-height: 30px;
}

.private-modal-win-qr {
    margin-right: 20px;
    width: auto;
    font-size: 12px;
    font-weight: 500;
    color: #3a3a3b;
    line-height: 30px;
    cursor: pointer;
}

.private-modal-win-qr-selected {
    margin-right: 20px;
    width: auto;
    font-size: 12px;
    font-weight: bold;
    color: #1958D9;
    line-height: 30px;
    border-bottom: 2px solid #1958D9;
    cursor: pointer;
}

.private-modal-win-qr-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 35px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #D9E0E9;
}

.private-modal-win-qr-content-text {
    text-align: center;
    width: 85%;
    height: 50%;
    font-size: 12px;
    color: #2F3241;
    line-height: 18px;
    border: 0;
}

.private-modal-win-qr-content-copy {
    text-align: center;
    width: 15%;
    background: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    color: #236BF3;
    line-height: 18px;
    border: 0;
    border-left: 1px solid #D9E0E9;
    cursor: pointer;
}

.private-modal-win-container-qr {
    margin: 0 auto;
    width: 120px;
    height: 120px;
}

.private-modal-win-reason {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
}

.private-modal-win-tip {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
}

.private-modal-win-service {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 80%;
    height: 30%;
    background: #F8FAFF;
    border-radius: 8px;
}

.private-modal-win-service-text {
    display: flex;
    align-items: center;
    width: 40%;
    height: 40%;
    font-size: 12px;
    color: #727682;
}

.private-modal-win-button {
    width: 280px;
    height: 45px;
    background: #1859DE;
    border: 0;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 22px;
    cursor: pointer;
}

.private-modal-win-close {
    z-index: 99;
    position: fixed;
    top: calc(50% - 200px);
    left: calc(50% + 250px);
    padding-left: 1%;
    width: auto;
    height: auto;
    transform: scale(0.8);
    border: 0;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    font-size: 22px;
    color: white;
}

/* mobile */
.mobile-private-layout {
    width: 100%;
    height: auto;
}

.mobile-private-wrapper {
    z-index: 1;
    margin-top: 80px;
    width: 100%;
    height: auto;
}

.mobile-private-banner {
    width: 100%;
    height: 300px;
    background: #F9F9FB;
    border-radius: 20px 20px 0px 0px;
    overflow: hidden;
}

.mobile-private-banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.mobile-private-banner-content {
    position: relative;
    top: -250px;
    text-align: center;
    margin: 0 auto;
    width: 85%;
    height: auto;
}

.mobile-private-banner-content-slogan {
    line-height: 30px;
    font-size: 22px;
    font-weight: bolder;
    color: white;
    text-align: center;
}

.mobile-private-banner-content-slogan-en {
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 40px;
}

.mobile-private-purchase {
    margin: -180px auto;
    width: 100%;
    height: 500px;
    background: #F9F9FB;
}

.mobile-private-purchase-label-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
    margin: 0 auto;
    width: 80%;
    height: 80px;
}

.mobile-private-purchase-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    height: auto;
    opacity: 0.5;
}

.mobile-private-purchase-label-selected {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    height: auto;
}

.mobile-private-purchase-label-index {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #337AF0;
    background: url("../../assets/icon/icon_label.svg") no-repeat center / cover;
    object-fit: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.mobile-private-purchase-label-text {
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 33px;
    white-space: nowrap;
}

.mobile-private-purchase-label-line {
    position: relative;
    top: -15px;
    width: 10%;
    height: 1px;
    border: 1px dashed rgba(255, 255, 255, 0.5);
}

.mobile-private-purchase-table {
    z-index: 10;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px auto;
    padding: 5% 5%;
    width: 90%;
    height: 400px;
    border-radius: 20px;
    box-shadow: 0 0 20px #c2c2c2;
    background-color: white;
}

.mobile-private-record {
    margin: 180px auto;
    width: 100%;
    height: auto;
}

.mobile-private-record-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    width: 100%;
}

.mobile-private-purchase-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;
}

.mobile-private-purchase-header-title {
    font-size: 16px;
    font-weight: bold;
    color: black;
}

.mobile-private-purchase-header-rule {
    font-size: 12px;
    color: blue;
}

.mobile-private-purchase-price {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    padding: 0 5%;
    width: 100%;
    height: 15%;
    border: 0;
    border-radius: 15px;
    background-color: rgba(161, 161, 161, 0.1);
}

.mobile-price-icon {
    margin-right: 2%;
    font-size: 14px;
    color: blue;
}

.mobile-price-bold {
    font-size: 12px;
    font-weight: bold;
    color: black;
}

.mobile-price-gray {
    font-size: 12px;
    color: gray;
}

.mobile-private-purchase-input {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 50%;
}

.mobile-private-purchase-input-element {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5%;
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: 1px solid rgba(128, 128, 128, 0.5);
}

.mobile-input-element-text {
    width: 25%;
    font-size: 12px;
    color: gray;
}

.mobile-input-element-num {
    padding-left: 5px;
    width: 50%;
    font-size: 14px;
    font-weight: bold;
    border: 0;
    background: #FFFFFF !important;
    color: #0D1E3D !important;
}

.mobile-input-element-currency {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 25%;
    height: 60%;
    border: 0;
    border-radius: 5px;
    background-color: rgba(161, 161, 161, 0.1);
}

.mobile-input-element-currency-one {
    padding-left: 5px;
    font-size: 12px;
    color: #123efc;
}

.mobile-input-element-currency-two {
    padding-left: 5px;
    font-size: 12px;
    color: #13a613;
}

.mobile-private-purchase-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 50px;
    border: 0;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    background-color: #337AF0;
}

.mobile-private-check-title {
    text-align: center;
    font-size: 16px;
    font-weight: bolder;
    color: #0D1E3D;
    line-height: 30px;
}

.mobile-private-check-info {
    display: flex;
    flex-direction: column;
    padding: 2% 5%;
    width: 100%;
    height: 220px;
    background: #F9F9FB;
    border-radius: 16px;
}

.mobile-private-check-info-ele {
    display: flex;
    flex-direction: row;
}

.mobile-private-check-info-title {
    width: auto;
    font-size: 12px;
    font-weight: 400;
    color: #9EA4B0;
    line-height: 35px;
}

.mobile-private-check-info-content {
    padding-left: 10px;
    font-size: 12px;
    color: #0D1E3D;
    line-height: 35px;
}

.mobile-private-check-button {
    margin: 0 auto;
    width: 100%;
    height: 50px;
    background: #337AF0;
    border: 0;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
}

.mobile-private-check-tip {
    display: table;
    text-align: center;
    margin: 5px auto 0;
    width: 86%;
    height: 50px;
    font-size: 12px;
    color: #777777;
    line-height: 20px;
    word-break: normal;
}

.mobile-private-finish-img {
    margin: 30px auto;
    width: 100px;
    height: 100px;
}

.mobile-private-finish-title {
    text-align: center;
    font-size: 16px;
    font-weight: bolder;
    color: #0D1E3D;
    line-height: 45px;
}

.mobile-private-finish-orderNo {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #777777;
    line-height: 37px;
}

.mobile-private-finish-button {
    margin: 0 auto;
    width: 100%;
    height: 50px;
    background: #337AF0;
    border: 0;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
}

.mobile-private-finish-tip {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 50px;
    font-size: 12px;
    color: #777777;
    line-height: 20px;
}

.mobile-private-modal {
    z-index: 20;
    position: absolute;
    top: 80px;
    width: 100%;
    height: 760px;
    background: #F9F9FB;;
}

.mobile-private-modal-win-bg {
    width: 100%;
    height: 30%;
}

.mobile-private-modal-win {
    position: relative;
    top: -25%;
    margin: 0 auto;
    width: 90%;
    height: 90%;
    border-radius: 30px;
    background: #FFFFFF;
}

.mobile-private-modal-win-title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 46px;
    color: #0D1E3D;
}

.mobile-private-modal-win-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 20px;
    width: 100%;
    height: auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    color: #727682;
}

.mobile-private-modal-win-container-title {
    font-size: 14px;
    font-weight: 400;
    color: #3D4B62;
    line-height: 36px;
}

.mobile-private-modal-win-container-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 20px;
    width: 100%;
    height: 115px;
    background: #F8FAFF;
    border-radius: 8px;
}

.mobile-private-modal-win-container-sub-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.mobile-private-modal-win-qr {
    margin-right: 20px;
    width: auto;
    font-size: 14px;
    font-weight: 500;
    color: #3a3a3b;
    line-height: 30px;
    cursor: pointer;
}

.mobile-private-modal-win-qr-selected {
    margin-right: 20px;
    width: auto;
    font-size: 14px;
    font-weight: bold;
    color: #1958D9;
    line-height: 30px;
    border-bottom: 2px solid #1958D9;
    cursor: pointer;
}

.mobile-private-modal-win-container-text {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    color: #0D1E3D;
}

.mobile-private-modal-win-button {
    margin: 20px;
    width: calc(100% - 40px);
    height: 60px;
    border: 0;
    border-radius: 8px;
    background: #1859DE;
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 22px;
}

.mobile-private-modal-win-tip {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
}


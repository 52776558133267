/* service */
.service-layout {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    min-width: 1280px;
    max-width: 2200px;
}

.service-layout-wrapper {
    position: relative;
    width: calc(100% - 250px);
    height: 100vh;
    min-height: 939px;
    background-color: rgb(43, 92, 220);
}

.service-layout-container {
    position: relative;
    top: 3%;
    padding: 1% 2%;
    width: 100%;
    height: 97%;
    background-color: #e5e5e5;
    border: 0;
    border-top-left-radius: 20px;
}

.service-layout-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.service-layout-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5%;
}

.service-layout-title {
    position: relative;
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    margin: auto 0;
    width: auto;
    height: 25px;
    border-left: 4px solid #236BF3;
}

.service-layout-title-cn {
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: auto;
    height: 100%;
    font-size: 22px;
    font-weight: bolder;
    color: black;
}

.service-layout-title-en {
    display: flex;
    /*align-items: flex-end;*/
    padding-left: 10px;
    width: auto;
    height: 100%;
    font-size: 18px;
    color: rgba(139, 139, 160, 1);
}

.service-layout-account {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
}

.service-layout-main {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: auto;
}

.service-layout-main-service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 23%;
    height: 180px;
    background-color: #FFFFFF;
    border-radius: 10px;
}

.service-layout-main-service-icon {
    width: 60px;
    height: 60px;
}

.service-layout-main-service-icon-img {
    width: 100%;
    height: 100%;
}

.service-layout-main-service-title {
    display: flex;
    align-items: center;
    margin-top: 10px;
    height: 15%;
    font-size: 12px;
    font-weight: 400;
    color: #727682;
    line-height: 30px;
}

.service-layout-main-service-des {
    display: flex;
    align-items: center;
    height: 15%;
    font-size: 12px;
    font-weight: bold;
    color: #2F3241;
    line-height: 30px;
}

/* mobile */
.mobile-service-layout {
    width: 100%;
    height: auto;
}

.mobile-service-wrap {
    margin: 0 auto;
    width: 90%;
    height: 200px;
}

.mobile-service-title {
    position: relative;
    width: 100%;
    height: 30%;
    font-size: 18px;
    font-weight: bolder;
    color: #2F3241;
    line-height: 26px;
}

.mobile-service-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 600px;
}

.mobile-service-ele {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 5%;
    width: 100%;
    height: 70px;
    background: #F9F9FB;
    border-radius: 15px;
}

.mobile-service-ele-title {
    font-size: 16px;
    font-weight: 600;
    color: #0D1E3D;
    line-height: 46px;
}

.mobile-service-ele-des {
    font-size: 14px;
    font-weight: 400;
    color: #3D4B62;
    line-height: 40px;
}

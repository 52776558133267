/* assets */
.assets-layout {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    min-width: 1280px;
    max-width: 2200px;
}

.assets-layout-wrapper {
    position: relative;
    width: calc(100% - 250px);
    height: 100vh;
    min-height: 939px;
    background-color: rgb(43, 92, 220);
}

.assets-layout-container {
    position: relative;
    top: 3%;
    padding: 1% 2%;
    width: 100%;
    height: 97%;
    background-color: #e5e5e5;
    border: 0;
    border-top-left-radius: 20px;
}

.assets-layout-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.assets-layout-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5%;
}

.assets-layout-title {
    position: relative;
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    margin: auto 0;
    width: auto;
    height: 25px;
    border-left: 4px solid #236BF3;
}

.assets-layout-title-cn {
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: auto;
    height: 100%;
    font-size: 22px;
    font-weight: bolder;
    color: black;
}

.assets-layout-title-en {
    display: flex;
    /*align-items: flex-end;*/
    padding-left: 10px;
    width: auto;
    height: 100%;
    font-size: 18px;
    color: rgba(139, 139, 160, 1);
}

.assets-layout-account {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
}

.assets-layout-main {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
    height: 200px;
}

.assets-layout-assets {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2% 2%;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 20px;
    background-color: white;
}

.assets-layout-assets-title {
    position: relative;
    width: 100%;
    height: 15%;
    font-size: 18px;
    font-weight: bolder;
    color: #2F3241;
    line-height: 26px;
}

.assets-layout-assets-content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1%;
    width: 100%;
    height: 60%;
}

.assets-layout-assets-element {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0 10px;
    width: 31%;
    height: 100%;
    border-radius: 10px;
}

.assets-layout-assets-element-icon {
    width: 50px;
    height: 50px;
}

.assets-layout-assets-element-icon-img {
    width: 100%;
    height: 100%;
}

.assets-layout-assets-element-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
    height: 50%;
}

.assets-layout-assets-element-title {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 400;
    color: #707A8A;
    line-height: 18px;
}

.assets-layout-assets-element-num {
    font-size: 20px;
    font-weight: bolder;
    color: #2F3241;
    line-height: 32px;
}

.assets-layout-msg {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
    height: 850px;
    border: 0;
    border-radius: 20px;
    background-color: white;
}

.assets-layout-refresh {
    z-index: 10;
    position: absolute;
    top: 3%;
    right: 2%;
}

.assets-layout-refresh-button {
    width: 70px;
    background: #F8FAFF;
    border-radius: 8px;
    border: 1px solid #1858DB;
    font-size: 14px;
    font-weight: 400;
    color: #1858DB;
    line-height: 30px;
    cursor: pointer;
}

.assets-layout-record {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 20px;
    background-color: white;
}

.assets-layout-record-title {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 6%;
    font-size: 18px;
    font-weight: bolder;
    color: #2F3241;
    line-height: 26px;
}

.assets-layout-record-title-type {
    height: 100%;
    margin-right: 50px;
    cursor: pointer;
}

.assets-layout-record-title-type-selected {
    height: 100%;
    margin-right: 50px;
    border-bottom: 2px solid #1859DE;
    cursor: pointer;
}

.assets-layout-record-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90%;
}

.assets-layout-record-table-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.assets-layout-record-empty {
    width: auto;
    height: auto;
    transform: scale(0.8);
    overflow: hidden;
}

.assets-layout-record-empty-text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
}

/* modal */
.assets-modal {
    z-index: 20;
    position: absolute;
    width: calc(100% + 250px);
    height: 103%;
    left: -250px;
    top: -3%;
    background: rgba(0, 0, 0, 0.7);
}

.assets-modal-win {
    z-index: 20;
    position: fixed;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    top: calc(50% - 190px);
    left: calc(50% - 250px);
    padding: 2% 0;
    width: 500px;
    height: 380px;
    background: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
    border-radius: 20px;
    white-space: pre-wrap;
}

.assets-modal-win-icon {
    width: 100px;
    height: 100px;
}

.assets-modal-win-icon-img {
    width: 100%;
    height: 100%;
}

.assets-modal-win-title {
    font-size: 18px;
    font-weight: bolder;
    color: #333333;
    line-height: 25px;
}

.assets-modal-win-reason {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
}

.assets-modal-win-tip {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
}

.assets-modal-win-button {
    width: 50%;
    height: 50px;
    background: #1859DE;
    border: 0;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 22px;
}

.assets-modal-win-close {
    position: fixed;
    top: calc(50% - 200px);
    left: calc(50% + 250px);
    padding-left: 1%;
    width: auto;
    height: auto;
    border: 0;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    font-size: 28px;
    color: white;
}

/* mobile */
.mobile-assets-layout {
    width: 100%;
    height: auto;
}

.mobile-assets-layout-wrapper {
    width: 100%;
    height: auto;
}

.mobile-assets-layout-assets {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    background-color: #3074F1;
    border: 0;
    border-radius: 15px 15px 0 0;
}

.mobile-assets-layout-assets-ttl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60%;
}

.mobile-assets-layout-assets-ttl-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
}

.mobile-assets-layout-assets-ttl-num {
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 30px;
}

.mobile-assets-layout-assets-det {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30%;
}

.mobile-assets-layout-assets-det-ele {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 50%;
}

.mobile-assets-layout-assets-det-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
}

.mobile-assets-layout-assets-det-num {
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 30px;
}

.corner-area {
    width: 100%;
    background: #F9F9FB;
}

.corner {
    margin: 0 auto;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #2468F2;
}

.mobile-assets-layout-record {
    width: 100%;
    height: auto;
    background: #F9F9FB;
}

.mobile-assets-layout-record-title {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 auto;
    width: 90%;
    height: 50px;
    font-size: 18px;
    font-weight: bolder;
    color: #2F3241;
    line-height: 26px;
}

.mobile-assets-layout-record-type {
    font-size: 18px;
    color: #0D1E3D;
    line-height: 46px;
    word-break: break-word;
}

.mobile-assets-layout-record-type-selected {
    font-size: 18px;
    color: #0D1E3D;
    line-height: 46px;
    word-break: break-word;
}

.mobile-assets-layout-record-type-selected:after{
    content: '';
    display: block;
    width: 30px;
    border-bottom: 3px solid #1859DE;
    margin: 0 auto;
}

.mobile-assets-layout-record-table-container {
    margin: 0 auto;
    padding: 0 0 20px;
    width: 95%;
    height: auto;
}

.mobile-assets-layout-record-table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px auto;
    padding: 5%;
    width: 100%;
    height: 200px;
    background: #FFFFFF;
    border-radius: 16px;
}

.mobile-assets-layout-record-table-icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 20%;
}

.mobile-assets-layout-record-table-pot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.mobile-assets-layout-record-table-icon {
    width: 24px;
    height: 24px;
}

.mobile-assets-layout-record-table-icon-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    width: calc(100% - 84px);
    font-size: 14px;
    font-weight: bold;
}

.mobile-assets-layout-record-table-icon-text-fail {
    display: flex;
    justify-content: center;
    width: 60px;
    font-size: 14px;
    color: #DF5060;
    line-height: 38px;
    white-space: nowrap;
}

.mobile-assets-layout-record-table-num-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80px;
    border-bottom: 1px dashed #E5E5E5;
}

.mobile-assets-layout-record-table-num-ele {
    width: 150px;
    height: 100%;
}

.mobile-assets-layout-record-num {
    font-size: 14px;
    font-weight: bold;
    color: #0D1E3D;
    line-height: 40px;
}

.mobile-assets-layout-record-num-title {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #777777;
    line-height: 20px;
    white-space: nowrap;
}

.mobile-assets-layout-record-table-order-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 30px;
}

.mobile-assets-layout-record-table-order-info {
    font-size: 12px;
    color: #666666;
    line-height: 38px;
}

.mobile-assets-layout-record-empty-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    width: 95%;
    height: 400px;
}

.mobile-assets-layout-record-empty {
    width: 100px;
    height: 100px;
    overflow: hidden;
}

.mobile-assets-layout-record-empty-text {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 40px;
}
